import React, { useState } from "react";
import { useGameController } from "./hooks/useGameController.ts";
import './assets/css/menu.css';
import LogoIcon from './assets/img/logo.svg';
import { useModalStore } from "./stores/modal.ts";
import { useAuthStore } from "./stores/auth.ts";
export default function Menu() {

    const gameController = useGameController();
    const modalState = useModalStore();
    const authState = useAuthStore();
    
    const [username, setUsername] = useState(authState.user?.username ?? gameController.player.username);

    gameController.addEventHandler(['username:updated'], 'menu', (event, data)=>{
        setUsername(gameController.player.username);
    });

    function start(){
        gameController.setGameStatus("playing");
    }

    const logout = ()=>{
        authState.logout();
        gameController.player.setUsername(gameController.player.generateUsername());
    }
    
    return (
        <main className="menu">
            <nav>
                <div className="account-container">
                    <label style={{display: 'flex', alignItems: 'center', fontSize: 14, fontFamily: 'Sen', fontWeight: 500}}>
                        Your username
                        { authState.user ? <i className="verified-badge"/> : null }
                    </label>
                    <span style={{fontSize: 12, padding: '5px 10px', borderRadius: 5, backgroundColor: 'rgba(0,0,0,0.2)', color: '#444'}}>{username}</span>
                    {
                        authState.user ?
                        null :
                        <span style={{textAlign: 'center', fontSize: '12px', opacity: 0.5}}>or</span>
                    }
                    {
                        authState.user ?
                        <button className="btn danger" onClick={logout}>Log out</button> :
                        <button className="btn secondary" onClick={()=>{ modalState.showLoginModal()}}>Log in with Code-Garage</button>
                    }
                </div>
                <header>
                    <img className={'logo'} src={LogoIcon} alt=""/>
                    <div>
                        <h1>Code Adventure</h1>
                        <h2>by Code-Garage</h2>
                    </div>
                </header>
                <div className="menu-buttons">
                    <button className="btn primary xl" onClick={start}>Play</button>
                    <a className="btn secondary lg" target="_blank" href={'https://8fc6233a.sibforms.com/serve/MUIFAIJs0_7_q_tKkqd8OksCaMrqtM7pmKxzQxPaDNW1qMqmvFgc7-6j2ET-nvdJFnQFsXV1DkOclk8Lruw4VsoeLgmIvnhwCQ70FMQVSTbmrlr3VXIVM6WHCIbCWkawboCkFGb6Iw0ij8cbJG0EZTm__CwQUTijRxuphPyIW-t3Nq92P_CODtqdnFiINFOV5gDCkcu-lRMdXcSk'}>Get my early access</a>
                </div>
            </nav>
            <p id="version">version {process.env.REACT_APP_VERSION}</p>
        </main>
    );
}