import React, { useEffect, useState } from "react";
import useWindowSize from 'react-use/lib/useWindowSize';
import { useGameController } from "../hooks/useGameController.ts";
import Confetti from 'react-confetti';

export function QuestSuccessOverlay() {
    const [show, setShow] = useState(false);
    const { width, height } = useWindowSize()

    const gameController = useGameController();
    useEffect(() => {
        gameController.addEventHandler(["quest:completed"], 'quest-success-overlay', () => {
            // Delay to let the user see the scene changing
            setTimeout(() => {
                setShow(true);
                gameController.getAudioController().playAudio("success", 0.7);
                setTimeout(() => {
                    setShow(false);
                }, 3000);
            }, 1000);
        });
    }, []);
    return show ? (
        <div className="quest-success-overlay">
            <Confetti
            width={width}
            height={height}
            initialVelocityY={30}
            />
            <div className="quest-success-container">
                <h1 className="quest-success-title">Quest Completed!</h1>
            </div>
            <p>New quest available</p>
        </div>
    ) : null;
}
