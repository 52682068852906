export default [
    {
        key: "first",
        title: "The adventure begins",
        next: "bedroom",
        experience: 5,
        tasks: [
            {
                title: "Get your letter",
                description: "There is a enveloppe for you in front of your door",
                completed: false,
                sceneState: "acceptance-letter:read"
            },
            {
                title: "Get the scholarship money",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "money:taken"
            },
            {
                title: "Go to the store",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "shop:travelled"
            },
            {
                title: "Buy a new computer",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "laptop:bought"
            }
        ]
    },
    {
        key: "bedroom",
        title: "The first challenge",
        next: "second",
        experience: 5,
        tasks: [
            {
                title: "Go back to your bedroom",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "bedroom:travelled"
            },
            {
                title: "Install your computer",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "briefcase:taken",
            },
            {
                title: "Pass the test on your computer",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "quiz:success",
            },
        ]
    },
    {
        key: "second",
        title: "Welcome to the company",
        next: "third",
        experience: 5,
        tasks: [
            {
                title: "Go to the office",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "office:travelled"
            },
            {
                title: "Talk to the boss",
                description: "She is waiting for you in the lobby",
                completed: false,
                sceneState: "boss:talked",
                target: "boss"
            },
            {
                title: "Try the arcade game",
                description: "She is waiting for you in the lobby",
                completed: false,
                sceneState: "arcade:interacted"
            },
            {
                title: "Get a snack in the kitchen",
                description: "She is waiting for you in the lobby",
                completed: false,
                sceneState: "snack:taken"
            },
        ]
    },
    {
        key: "third",
        title: "Start the work",
        next: "fourth",
        experience: 5,
        tasks: [
            {
                title: "Meet the team",
                description: "Meet the team and introduce yourself",
                completed: false,
                sceneState: "coworker:talked"
            },
            {
                title: "Complete your first mission",
                description: "Meet the team and introduce yourself",
                completed: false,
                sceneState: "challenge:succeeded"
            }
        ]
    },
    {
        key: "fourth",
        title: "First school day",
        experience: 5,
        tasks: [

            {
                title: "Go to your school",
                description: "Set up your computer and install the necessary software",
                completed: false,
                sceneState: "school:travelled"
            },
            {
                title: "Have fun",
                description: "She is waiting for you in the lobby",
                completed: false,
                sceneState: "none"
            },
        ]
    },
    {
        key: "fifth",
        title: "",
        experience: 5,
        tasks: [
        ]
    }
]