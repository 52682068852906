import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type {} from '@redux-devtools/extension' // required for devtools typing
import { useStrapiUtils } from '../hooks/useStrapiUtils.ts';

interface AuthState {
    jwt?: string | null,
    user?: any,
    error?: any,
    login: (email: string, password: string) => Promise<any>,
    setUser: (user: any) => void,
    logout: () => void,
    load: () => Promise<void>
}

const { parseError } = useStrapiUtils();

export const useAuthStore = create<AuthState>()(
  devtools(
      (set, get) => ({
        authUser: null,
        login: async (email, password) => {
            const response = await fetch(
            'https://code-garage-cms.cleverapps.io/api/auth/local', 
            {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ identifier: email, password: password }),
            });
            const data = await response.json();
            if(!data.jwt) {
                set({ error: parseError(data.error) });
                return;
            }
            get().setUser({ user: data.user, jwt: data.jwt });
            return data;
        },
        setUser: ({jwt, user}) => {
            localStorage.setItem('jwt', jwt);
            set({ user, jwt });
        },
        logout: () => {
            set({ user: null, jwt: null });
            localStorage.removeItem('jwt');
        },
        load: async () => {
            const jwt = localStorage.getItem('jwt');
            let data = null;
            if(jwt){
                const response = await fetch(
                    'https://code-garage-cms.cleverapps.io/api/users/me', 
                    {
                        method: 'GET',
                        headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${jwt}`,
                        },
                    });
                data = await response.json();
                if(data){
                    get().setUser({ jwt, user:data });
                } else {
                    get().logout();
                }
            }
            
        }
      }),
      {
        name: 'auth-storage',
      },
    ),
)