

import CanIcon from './img/items/soft-drink.svg';
import EnergyCanIcon from './img/items/energy-drink.svg';
import HotDogIcon from './img/items/hotdog.svg';

const letterText = `
# Acceptance letter

Dear Mr. Smith,


We are very proud to announce you have been accepted to take part in our next **Computer Science** program.

You have also been awarded a **scholarship** of :

## 900$

**Your next** steps are the following :

* Buy a **laptop**
* Discover your training **company**
* **Learn** the basics of programming

**We will then welcome you at school** for the first semester!

</br>
</br>
<p style='text-align: right;'>
Sincerely yours,</p>
<p style='text-align: right;'>
Mr. Johnson.</p>

<p style='text-align: right;'>
<img src="/img/stamp.png"/>




<small><center>(click anywhere to close the letter)</center></small>
`

const bookPages = [`
# Welcome to Code Adventure

You're currently playing to the **world-first** adventure video game in which you can learn **how to code while playing** !

## Warning

This is a **public demo**, which includes only three levels for now. It is meant to showcase the game's potential and the learning experience it provides.

But it is far from being finished, we have a ton of ideas **being implemented right now !**

<center><img src="img/books/idea.png"/></center>

## How to play

To progress in the game, you only have to **use your keyboard** (ZQSD or WASD) to move your character, and **drag the mouse** to move the camera.


Each object you can interact with will be activated using your (E) key.

## Changelog

_coming soon_
`,
`
## Quests

On the top right corner, you will see your current quest with a list of tasks to complete.

By completing a task, you'll learn experience points and unlock new levels. But most importantly, these tasks will help you to progress in the game and **learn computer programming!**

_Experience and level have no influence on the game yet, but it feels good right?_

## Money

By completing quests at the office, you will earn some in-game money.

You can use this money in vending machine to get some sodas, because why not?

_Be careful, if you drink too much, you might feel the urge to pee..._

<center><img src="img/books/wallet.png"/></center>

## Are you an investor?

We're looking to raise some funding to develop the game further. If you're interested, please contact us at [nicolas@code-garage.fr](mailto:nicolas@code-garage.fr)
`,
`
If we have anything else to say, we will write it here.
`,
`
Or here.
`]

export default {
    bedroom: {
        key: "bedroom",
        scene: {
            model: "data/models/bedroom-scene.gltf",
            collider_model: "data/models/bedroom-collider.fbx",
            backgroundMusic: "bedroom"
        },
        elements: [
            { type: 'item', key: 'enveloppe', position: [-1.5, 0.5, 1.8], rotation: [-80,0.0,0.0], scale: [0.015,0.015,0.015], data: {sceneState: "acceptance-letter:read", content: letterText}  /*, sceneStateCondition: 'briefcase:taken'*/},
            { type: 'item', key: 'briefcase', position: [2.5, 0.5, 0], rotation: [0.0,0.0,0.0], scale: [0.01,0.01,0.01], data: { sceneState: "briefcase:taken"}, sceneStateCondition: "laptop:bought"},
            { type: 'item', key: 'book', position: [4, 1, 4.5], rotation: [5.0,0.0,5.0], scale: [0.015,0.015,0.015], data: { content: bookPages}},
            { type: 'zone', key: 'level', position: [-1.8, 0.5, 1.8], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { levels: [{name:"bedroom"},{name:"office", sceneStateCondition: "quiz:success"}, {name:"shop"}] }, sceneStateCondition: "money:taken"},
            { type: 'zone', key: 'game', position: [-1.1, 0.2, 4], rotation: [0.0,0.0,0.0], scale: [0.3,0.2,0.3], data: { url: "https://miniroyale.io/", hint: "Play game", screenRatio: '16 / 9', invisible: true }},
            { type: 'zone', key: 'bed', position: [3, 0.5, 1.5], rotation: [0.0,0.0,0.0], scale: [0.5,0.2,0.5], data: { hint: "Sleep", invisible: true}},
            { type: 'prop', key: 'laptop', position: [0.65, 0.75, 0], rotation: [0.0,0.0,0.0], scale: [1.0,1.0,1.0], sceneStateCondition: 'briefcase:taken'},
            //{ type: 'zone', key: 'computer', position: [0.8, 0.5, 0.8], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], sceneStateCondition: 'briefcase:taken', data: { quiz: "introduction"}},
            { type: 'item', key: 'money', position: [1.5, 1, 2], rotation: [0.0,0.0,20.0], scale: [0.02,0.02,0.02], data: { amount: 900}, sceneStateCondition: 'acceptance-letter:read'},
            { type: 'zone', key: 'computer', position: [1.2, 0.5, 0.9], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], sceneStateCondition: 'briefcase:taken', data: { computer: true, defaultTab: "quiz", disabledTabs: ["missions"]}},
        ],
        player: {
            position: [2, 5 , 2],
            rotation: 0
        }
    },
    shop: {
        key: "shop",
        scene: {
            model: "data/models/shop-scene.gltf",
            collider_model: "data/models/shop-collider.fbx",
            backgroundMusic: "shop"
        },
        elements: [
            { type: 'zone', key: 'level', position: [5.5, 0.7, 5], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { levels: [{name: 'shop'},{ name: "bedroom"}] }, sceneStateCondition: "laptop:bought"},
            { type: 'npc', key: 'salesman', position: [-8, 0.5, 6], rotation: [0.0,Math.PI/2.2,0.0], scale: [1,1,1]},
            { type: 'npc', key: 'clerk', position: [1.5, 0.5, 9], rotation: [0.0,-Math.PI/2.2,0.0], scale: [1,1,1]},
        ],
        player: {
            position: [4, 5 , 6],
            rotation: Math.PI/2
        }
    },
    office: {
        key: "office",
        scene: {
            model: "data/models/office-scene.gltf",
            collider_model: "data/models/office-collider.fbx",
            backgroundMusic: "office"
        },
        elements: [ 
            { type: 'item', key: 'snack', position: [-6, 0.5, -6], rotation: [0.0,0.0,0.0], scale: [0.02,0.02,0.02], data: {sceneState: 'snack:taken'}, sceneStateCondition: 'arcade:interacted'},
            { type: 'npc', key: 'boss', position: [-1.5, 0, -3], rotation: [0.0,5.0,0.0], scale: [1,1,1]},
            { type: 'npc', key: 'developer', position: [3.9, -0.3, 7.5], rotation: [0.0,3.0,0.0], scale: [1,1,1], sceneStateCondition: 'snack:taken'},
            { type: 'zone', key: 'level', position: [-2.4, 0.5, -7], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { levels: [{name: 'office'},{name: 'school'}] }, sceneStateCondition: 'challenge:succeeded'},
            { type: 'zone', key: 'computer', position: [1.9, 0.5, 7.8], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { computer: true, defaultTab: "missions", disabledTabs: []}, sceneStateCondition: 'coworker:talked'},
            { type: 'zone', key: 'game', position: [0, 0.5, -1.3], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { url:  "https://funhtml5games.com?embed=spaceinvaders", sceneState: 'arcade:interacted', hint: "Play arcade", screenRatio: '4.22 / 3'}, sceneStateCondition: 'boss:talked'},
            { type: 'item', key: 'money', position: [-2.5, 1, -1], rotation: [0.0,0.0,20.0], scale: [0.02,0.02,0.02], data: { amount: 10}, sceneStateCondition: 'challenge:succeeded'},
            { type: 'zone', key: 'vending-machine', position: [-6, 0.5, -6], rotation: [0.0,0.0,0.0], scale: [0.5,0.5,0.5], data: { hint: "Buy", items: [
                {name: "Soda", key: "soda", price: 4, icon: CanIcon},
                {name: "Energy Drink", key: "soda", price: 6, icon: EnergyCanIcon},
                {name: "Hot Dog", key: "hotdog", price: 8, icon: HotDogIcon}
            ]}, sceneStateCondition: 'challenge:succeeded'},
            { type: 'zone', key: 'toilet', position: [-7,0.5,3.3], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
        ],
        player: {
            position: [-3, 5 , -3],
            rotation: 0 
        }
    },
    school: {
        key: "school",
        scene: {
            model: "data/models/school-scene.gltf",
            collider_model: "data/models/school-collider.fbx",
            backgroundMusic: "office"
        },
        elements: [
            { type: 'npc', key: 'teacher', position: [-19,0.6,-4], rotation: [0.0,2,0.0], scale: [1,1,1]},
            { type: 'zone', key: 'toilet', position: [-19,1,16], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
            { type: 'zone', key: 'toilet', position: [-17,1,16], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
            { type: 'zone', key: 'toilet', position: [-16,1,16], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
            { type: 'zone', key: 'toilet', position: [-17,1,19], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
            { type: 'zone', key: 'toilet', position: [-16,1,19], rotation: [0,0,0], scale: [0.5,0.5,0.5], data: { hint: "Pee", invisible: true}},
            { type: 'projector', key: 'projector', position: [-19.7,2.3,-7.4], rotation: [0,Math.PI/2,0], scale: [2,2,2], data:{video: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4'}, sceneStateCondition: 'course:started'}
        ],
        player: {
            position: [-0, 5 , 25],
            rotation: Math.PI
        }
    },
}
