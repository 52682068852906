import React, { useEffect, useState } from "react";
import "../assets/css/quest-gui.css";
import { useGameController } from "../hooks/useGameController.ts";

export default function QuestGUI(){

    const gameController = useGameController();
    const [currentQuest, setCurrentQuest] = useState(gameController.getGameState().currentQuest);

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentQuest({...newGameState.currentQuest});
        };
        gameController.addEventHandler(["task:completed", "game:updated"], 'quest-gui', handler);
        return () => {
            gameController.removeEventHandler(["task:completed", "game:updated"], 'quest-gui', handler);
        };
    }, []);
    
    return (
        <div className="quest-gui">
            <div className="quest-container">
                <p className="quest-title">{currentQuest.title}</p>
                <ul>
                {
                    (currentQuest?.tasks || []).map((task, index) => {
                        return index == 0 || currentQuest.tasks[index - 1].completed ? (
                            <li className="quest-task" key={task.title}>
                                {task.title} {task.completed ? "✅" : "❌"}
                            </li>
                        ) : null
                    })
                }
                </ul>
            </div>
        </div>
    )
}