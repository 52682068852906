import { useEffect, useState } from "react"
import { useGameController } from "../hooks/useGameController.ts";

export default function CourseGUI(){

    const [currentInteraction, setCurrentInteraction] = useState(null);
    const gameController = useGameController();

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'course-gui', handler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'course-gui', handler);
        }
    }, []);

    return currentInteraction && currentInteraction.type == 'course' ? (
        <button style={{position: 'absolute', zIndex: 10000, bottom: 20, right: 20, width: 200}} className="btn lg primary" onClick={()=>{gameController.setCurrentPlayerInteraction(null, null)}}>Close</button>
    ) : null;
}