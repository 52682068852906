import React, { useEffect, useRef, useState } from 'react';
import { Text, useAnimations, useGLTF } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { Euler, Vector3 } from 'three';
import {useFollowCam} from 'ecctrl';
import { myPlayer } from 'playroomkit';
import { CuboidCollider } from '@react-three/rapier';
import { useGameController } from './hooks/useGameController.ts';


export default function MultiPlayer({ player }) {
    const gameController = useGameController();
	const characterURL = 'data/models/MultiPlayer/player.gltf'
    const { scene, nodes, materials, animations } = useGLTF(characterURL);
    const [position, setPosition] = useState([0,0,0]);
    const [rotation, setRotation] = useState([0,0,0]);
    const [currentAnimation, setCurrentAnimation] = useState("Idle");
    const {mixer, actions} = useAnimations(animations, scene);

    useEffect(()=>{
        actions["Idle"].play();
    },[]);

    useFrame(() => {
        const p = player.getState("position");
        const r = player.getState("rotation");
        const a: string|undefined = player.getState("animation");
        if(p){
            setPosition([p.x, p.y, p.z]);
        }
        if(r){
            setRotation([r.x*Math.PI, r.y*Math.PI, r.z*Math.PI]);
        }
        if(a && actions[a] && a !== currentAnimation){
            if(currentAnimation && currentAnimation != "Idle") actions[currentAnimation].stop();
            actions[a].play();
            setCurrentAnimation(a);
        }
    });


    function onInteraction(){
        gameController.setCurrentPlayerInteraction("computer", { url: 'https://code-garage.fr/u/'+player.getState("username") });
    }

    function onIntersectionEnter(){
        if(player.getState("username").indexOf("#") === 0) return;
        gameController.setPlayerHint("(E) Interact")
        gameController.addEventHandler(["player:interacted"], "player:"+player.id, onInteraction);
    }

    function onIntersectionExit(){
        if(player.getState("username").indexOf("#") === 0) return;
        gameController.clearPlayerHint();
        gameController.removeEventHandler(["player:interacted"], "player:"+player.id, onInteraction);
    }
    

    return (
		<group position={position}>
            <Text 
                anchorX="center" 
                color={'white'} 
                anchorY="middle" 
                position={[0, 1.5, 0]} 
                rotation={new Euler(0, 0, 0)} 
                scale={[0.2, 0.2, 0.2]}
            >
                { player.getState("username") }
            </Text>
            <CuboidCollider 
                args={[0.75, 1, 0.75]} 
                sensor 
                onIntersectionEnter={onIntersectionEnter}
                onIntersectionExit={onIntersectionExit}
                >
                    
			    <primitive object={scene} rotation={rotation}  position={[0,-0.9,0]} scale={[1,1,1]} />

            </CuboidCollider>
		</group>
    )
  
};