import React, { useEffect, useState } from "react";
import "../assets/css/player-gui.css";
import { useGameController } from "../hooks/useGameController.ts";
import PlayerAvatarImage from "../assets/img/ui/player-avatar.png";
import { ProgressBar } from "../components/ProgressBar.tsx";

export default function QuestGUI(){

    const gameController = useGameController();
    const [level, setLevel] = useState(gameController.player.level);
    const [experience, setExperience] = useState(gameController.player.experience);
    const [nextLevelThreshold, setNextLevelThreshold] = useState(gameController.player.getNextLevelThreshold());
    const [money, setMoney] = useState(gameController.player.money);

    useEffect(() => {
        const handler = (event, playerData) => {
            setLevel(playerData.level);
            setExperience(playerData.experience);
            setMoney(playerData.money);
            setNextLevelThreshold(gameController.player.getNextLevelThreshold());
        };
        gameController.addEventHandler(["player:updated"], 'player-gui', handler);
        return () => {
            gameController.removeEventHandler(["player:updated"], 'player-gui', handler);
        };
    }, []);
    
    return (
        <div className="player-gui">
            <img className="player-avatar" src={PlayerAvatarImage} />
            <div className="player-info">
                <p className="player-username">
                    <span style={{flex: 1}}>{gameController.player.username}</span>
                    <span className="player-level">Lvl {level}</span>
                </p>
                <p className="player-money">Money: {money}$</p>
                <div class="player-experience">
                    <span>{experience} Exp</span>
                    <ProgressBar variant={['sm']} progress={((experience / nextLevelThreshold)*100) || 0}/>
                </div>
            </div>
        </div>
    )
}