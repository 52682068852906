import { GameController } from "./GameController.ts";

export default class GameInteractionController {
    gameController: GameController;

    handleKeyDown = (event) => {
        if(event.key === 'e' || event.key === 'E') {
            this.gameController.sendEvent("player:interacted");
        }
    };

    constructor(gameController) {
        this.gameController = gameController;
        window.addEventListener('keydown', this.handleKeyDown);
    }

    destroy() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }
    
}