import React, { useEffect, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";
import '../assets/css/login-modal.css';
import { useModalStore } from "../stores/modal.ts";
import { useAuthStore } from "../stores/auth.ts";
import LogoImage from '../assets/img/icons/code-garage.svg';

export default function LoginModal(){
    const modalsState = useModalStore();
    const authState = useAuthStore();
    const gameController = useGameController();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const login = async (e)=>{

        e.preventDefault();
        const data = await authState.login(email, password);
        if(data && data.user){
            gameController.player.setUsername(data.user.username);
            modalsState.hideLoginModal();
        }

    };

    return modalsState.loginModal ? (   <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}>
            <div onClick={()=>{modalsState.hideLoginModal()}} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.6)'}}>
            </div>
        
            <div className={'login'} style={{position: 'relative', zIndex: 10000,backgroundColor: 'white', maxWidth: '600px'}}>
                <form onSubmit={login}>
                    <img className="form-logo" src={LogoImage}/>
                    <p className="form-title">Login</p>
                    <p className="form-description">Connect to your Code-Garage profile to unlock some features</p>
                    <label className="label">Email</label>
                    <input className="field" type="text" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    <label className="label">Password</label>
                    <input className="field" type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                    {
                        authState.error ?
                        <div className="alert">{authState.error}</div> :
                        null
                    }
                    <button className="btn ternary" role="submit">Log in</button>
                    <a class="link" style={{textAlign: 'center', marginTop: 10, fontSize:14}} href="https://code-garage.fr?modal=signup" target="_blank">I don't have an account</a>
                </form>
            </div>
        </div>
        </>
    ) : null;
        
}