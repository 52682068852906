import React, { useEffect, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";
import '../assets/css/shop-gui.css';
import CloseIcon from '../assets/img/icons/close.svg';

export default function ShopGUI(){
    const [currentInteraction, setCurrentInteraction] = useState(null);
    const gameController = useGameController();

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'shop-gui', handler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'shop-gui', handler);
        }
    }, []);

    function close(){
        gameController.setCurrentPlayerInteraction(null, null);
    }

    function buy(item: {key: string, price: number, sceneState?: string}){
        const success = gameController.player.buy(item.key, item.price, item.sceneState);
        if(success){
            close();
        }
    }
    return currentInteraction && currentInteraction.type === 'shop' ?
       <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}>
            <div onClick={()=>{gameController.setCurrentPlayerInteraction(null, null)}} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            </div>
        
            <div className={'shop'} onClick={()=>close()}>
                <p className="shop-title">Buy</p>
                <p className="shop-subtitle">No discount available</p>
                <div className="shop-products">
                    {currentInteraction.data.items.map((item, index) => (
                        <button key={index} className="shop-product" onClick={()=>buy(item)}>
                            <div className="product-icon" style={{backgroundImage: 'url('+item.icon+')'}}></div>
                            <span className="product-name">{item.name}</span>
                            <span className="product-price">{item.price}$</span>
                        </button>
                    ))}
                    <div className="shop-product">
                    </div>
                </div>
                <button className="btn danger lg" style={{position: 'absolute', top:-20, right: -20, width: 60}} onClick={()=>close()}>
                    <img src={CloseIcon} style={{height: 20}} />
                </button>
            </div>
        </div>
    </>
    : null;
        
}