import CanIcon from './img/items/soft-drink.svg';
import EnergyCanIcon from './img/items/energy-drink.svg';
import HotDogIcon from './img/items/hotdog.svg';
import LaptopIcon from './img/items/laptop.svg';
import ComputerIcon from './img/items/desktop-computer.svg';
import Computer64Icon from './img/items/combo-computer.svg';

export const discussions = {
    clerk: {
        start: {
            text: "Hello! Would you like to buy something?",
            canLeave: true,
            options: [
                {
                    text: 'Yes, please',
                    interaction: { type: 'shop', data: { items: [
                        {name: "Soda", key: "soda", price: 4, icon: CanIcon},
                        {name: "Energy Drink", key: "soda", price: 6, icon: EnergyCanIcon},
                        {name: "Hot Dog", key: "hotdog", price: 8, icon: HotDogIcon}
                    ]}}

                }
            ]
        },
    },
    salesman: {
        start: {
            text: "Would you like to buy a computer ?",
            canLeave: false,
            options: [
                {
                    text: 'Yes, I need one for school!',
                    interaction: { type: 'shop', data: { items: [
                        {name: "Laptop", key: "laptop", price: 900, icon: LaptopIcon, sceneState: 'laptop:bought'},
                        {name: "Computer", key: "computer", price: 1200, icon: ComputerIcon},
                        {name: "Computer x64", key: "computer", price: 1500, icon: Computer64Icon}
                    ]}}
                }, 
                {
                    text: 'Another day, thanks!',
                }
            ]
        },
    },
    developer: {
        start: {
            text: "Hey, you're the new developer right?",
            canLeave: false,
            options: [
                {
                    text: 'Absolutely!',
                    next: 'first_mission',
                }, 
                {
                    text: 'Intern, but yes!',
                    next: 'first_mission'
                }
            ]
        },
        first_mission: {
            text: "Right on time, I've got a small issue with a customer, he changed his email address, can you help me fixing it ?",
            canLeave: false,
            options: [
                {
                    text: 'Let\'s do that!',
                    next: 'desk',
                }
            ]
        },
        desk: {
            text: "Wonderful! Your desk is the one on my left, we are desk-friends now!",
            canLeave: false,
            options: [
                {
                    text: 'So cool!',
                    sceneState: 'coworker:talked',
                    next: null,
                },

                {
                    text: 'Mmmh, okay.',
                    sceneState: 'coworker:talked',
                    next: null,
                }
            ]
        },
    },
    teacher: {
        start: {
            text: "Welcome to the Major Open University of South-East: the M.O.U.S.E!",
            canLeave: true,
            options: [
                {
                    text: 'Thank you!',
                    next: 'thank_you',
                }
            ]
        },
        thank_you: {
            text: "It's my pleasure. What do you want to do today?",
            canLeave: true,
            options: [
                {
                    text: `Take a course`,
                    next: 'course',
                }
            ]
        },
        course: {
            text: "Have a seat then, I'll start in a minute",
            options: [
                {
                    text: `(Sit down)`,
                    next: null,
                    sceneState: 'course:started'
                }
            ]
        }
    },
    boss: {
        start: {
            text: "Hello, and welcome to Megasoft!",
            canLeave: false,
            options: [
                {
                    text: 'Thanks',
                    next: 'thanks'
                }
            ]
        },
        thanks: {
            text: "It's my pleasure. We are there to help you learn and make progress!",
            canLeave: false,
            options: [
                {
                    text: `Sounds perfect`,
                    next: 'at_home',
                    sceneState: 'boss:talked'
                }
            ]
        },
        at_home: {
            text: "Feel free to wander around, and enjoy the office. If you need anything, you can ask your colleagues !",
            canLeave: false,
            options: [
                {
                    text: 'Amazing, I\'ll try the arcade',
                    next: null
                }
            ]
        }
    }
};