import { Cone, Dodecahedron, Polyhedron } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import React, { useEffect, useState } from "react";
import { Color, Vector3 } from "three";

export default function Fire({position}) {
    const MIN_HEIGHT = 1;
    const MAX_HEIGHT = 1.2;
    const HEIGHT_STEP = 0.005;
    const MAX_FLAME_TOP = 0.5;
    const [flames, setFlames] = useState([]);

    const [coreHeight, setCoreHeight] = useState(1);
    const [coreHeightStep, setCoreHeightStep] = useState(HEIGHT_STEP);

    useEffect(()=>{
        const flames = [];
        for(let i = 0; i < 5; i++){
            const r1 = Math.random();
            const r2 = Math.random();
            const r3 = Math.random();
            const r4 = Math.random();
            flames.push({
                position: [0.3*r3,0,0.3*r4],
                scale: [r2 * 0.08, r2 * 0.08, r2 * 0.08],
                speed: 0.01 + (r1 * 0.01),
                delay: r1 * 0.1
            });
        }
        setFlames(flames);
    }, []);

    useFrame((_, delta)=>{

        setCoreHeightStep(coreHeight > MAX_HEIGHT ? - HEIGHT_STEP : (coreHeight < MIN_HEIGHT ? HEIGHT_STEP : coreHeightStep));
        setCoreHeight(coreHeight + coreHeightStep);

        const newFlames = flames.map((flame, index) => {
            let delay = flame.delay - delta;
            let position = delay > 0 ? flame.position : [flame.position[0], flame.position[1] + flame.speed, flame.position[2]];
            let scale = flame.scale;
            if(position[1] > MAX_FLAME_TOP){
                const r1 = Math.random();
                const r2 = Math.random();
                const r3 = Math.random();
                const r4 = Math.random();
                position = [0.3*r1, 0, 0.3*r2];
                scale = [r4 * 0.08, r4 * 0.08, r4 * 0.08],
                delay = r3 * 0.1;
            }
            return {
                ...flame,
                position: position,
                scale: scale,
                delay: delay
            };
        });
        setFlames(newFlames);
    });
    return (
        <group position={position}>
            <group position={[2, 0, 4]} >
            <spotLight position={[0, 30, 25]} angle={0.07 * coreHeight} penumbra={0.8} decay={0.1} intensity={15 * coreHeight} color={new Color('orange')} castShadow/>
            </group>
            <group position={[-0.15,0.2,-0.15]}>
            { 
                flames.map((flame, index)=> (
                    <group scale={flame.scale} position={flame.position} key={index}> 
                        <Cone args={[1, 2, 4]} position={[0, 1, 0]}>
                            <meshStandardMaterial color="#FF0000" />
                        </Cone>
                        <Cone args={[1, 2, 4]} rotation={[Math.PI, 0, 0]} position={[0, -1, 0]} >
                            <meshStandardMaterial color="#FF0000" />
                        </Cone>
                    </group>
                ))
            }   
            </group>
        <Dodecahedron args={[0.2, 0]} scale={[1,coreHeight,1]} position={[0,0,0]}>
            <meshStandardMaterial color="#FF0000" opacity={0.8} transparent={true} />
        </Dodecahedron>
        </group>
    );
}