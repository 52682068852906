import React, { useEffect, useState } from "react";
import InteractionZone from "./InteractionZone.tsx";
import Item from "./Item.tsx";
import NPC from "./NPC.tsx";
import Player from "./Player.tsx";
import Scene from "./Scene.tsx";
import Prop from "./Prop.tsx";
import { useGameController } from "./hooks/useGameController.ts";
import ProjectorScreen from "./ProjectorScreen.tsx";
import { onPlayerJoin, insertCoin, myPlayer } from "playroomkit";
import MultiPlayer from "./MultiPlayer.tsx";
import { useAuthStore } from "./stores/auth.ts";
import Wall from "./Wall.tsx";
import { useFrame } from "@react-three/fiber";
import { Vector3 } from "three";

export default function Game({camera}){
    const authState = useAuthStore();
    const gameController = useGameController();
    const [levelKey, setLevelKey] = useState(gameController.gameState.sceneKey);
    const [currentLevel, setCurrentLevel] = useState(gameController.gameState.currentScene);
    const [sceneState, setSceneState] = useState(gameController.getGameState().sceneState);
    const [players, setPlayers] = useState([]);

    useEffect(()=>{
        gameController.addEventHandler(["scene:updated"], 'game',(event, gameState) => {
            setSceneState({...gameState.sceneState});
            setCurrentLevel(gameState.currentScene);
        });
        gameController.addEventHandler(["scene:loaded"], 'game',(event, gameState) => {
            setLevelKey(gameState.sceneKey);
            setCurrentLevel(gameState.currentScene);
            if(gameState.sceneKey === 'school'){
                insertCoin({gameId: "Udi0K1xZk8Gq5yy2zfmU", skipLobby: true, roomCode: "1234"},()=>{
                    myPlayer().setState("username", gameController.player.username);
                });
                //maxPlayersPerRoom: 2,
        
                onPlayerJoin((player) => {
                    if(player.id == myPlayer().id) return;
                    setPlayers([...players, player]);
                    player.onQuit(() => {
                        setPlayers(players.filter(p => p.id !== player.id));
                    });
                });
            }
        });
        
        gameController.loadScene('bedroom');
    }, []);

    useFrame(()=>{
        /*if(camera.current){
          const worldPosition = new Vector3();
            camera.current.getWorldPosition(worldPosition);
            console.log("Camera World Position:", worldPosition);
        }*/
      })
    

    return currentLevel ? (
        <>
            <ambientLight intensity={Math.PI}/>
            <spotLight position={[0, 100, 0]} angle={Math.PI/2} penumbra={1} decay={0} intensity={Math.PI} castShadow/>
            {/*
            <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
            */}
            <Player initialPosition={currentLevel.player.position} initialRotation={currentLevel.player.rotation} scale={[1,1,1]} />
            {
                /* hotfix : Could be implemented more easily, but otherwise scene Rigidbody doesn't update correctly */
                Object.values(gameController.levels).map((level: any) => <Scene isActive={level.key === levelKey} model={level.scene.model} collider={level.scene.collider_model} music={level.scene.backgroundMusic} key={level.key} />)
            }
            {
                currentLevel.elements.map((element, index)=> {
                    if((element.sceneStateCondition && !sceneState[element.sceneStateCondition]) || gameController.player.inventory[currentLevel.key+index]){
                        return null;
                    }
                    switch(element.type){
                        case 'prop' : return <Prop position={element.position} rotation={element.rotation} scale={element.scale} id={element.key} key={currentLevel.key+index} /> ;
                        case 'npc' : return <NPC position={element.position} rotation={element.rotation} scale={element.scale} id={element.key} key={currentLevel.key+index} /> ;
                        case 'item' : return <Item position={element.position} initialRotation={element.rotation} scale={element.scale} id={element.key} data={element.data} key={currentLevel.key+index} uid={currentLevel.key+index}/> ;
                        case 'zone' : return <InteractionZone position={element.position} initialRotation={element.rotation} scale={element.scale} id={element.key} data={element.data} key={currentLevel.key+index} uid={currentLevel.key+index} /> ;
                        case 'projector' : return <ProjectorScreen position={element.position} rotation={element.rotation} scale={element.scale} id={element.key} data={element.data} key={currentLevel.key+index} uid={currentLevel.key+index} /> ;
                    }
                })
            }
            {
                currentLevel.key === 'school' ? 
                players.map((player) => <MultiPlayer key={player.id} player={player} />)
                : null
            }
        </>
    ) : null
}