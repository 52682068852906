import React, { useEffect, useState } from "react";
import "../assets/css/map-gui.css";
import { useGameController } from "../hooks/useGameController.ts";
import MapImage from "../assets/img/ui/map.png";
import PlayerAvatarImage from "../assets/img/ui/player-avatar.png";
const locations = {
    bedroom: {name: "Home", position: [-18, -25]},
    school: {name: "School", position: [20, -27]},
    office: {name: "Office", position: [4, 19]},
    shop: {name: "Shop", position: [0, 0]},
}


export default function MapGUI(){

    const gameController = useGameController();
    const [currentPlayerInteraction, setCurrentPlayerInteraction] = useState(gameController.getGameState().currentPlayerInteraction);
    const [currentLevel, setCurrentLevel] = useState(gameController.getGameState().sceneKey);

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentPlayerInteraction(newGameState.currentPlayerInteraction);
        };
        const locationHandler = (event, newGameState) => {
            setCurrentLevel(newGameState.sceneKey);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'map-gui', handler);
        gameController.addEventHandler(["scene:loaded"], 'map-gui', locationHandler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'map-gui', handler);
            gameController.removeEventHandler(["scene:loaded"], 'map-gui', locationHandler);
        };
    }, []);

    function onMapPointClicked(level:string){
        gameController.loadScene(level);
        gameController.setCurrentPlayerInteraction('', null);
    }
    
    return currentPlayerInteraction && currentPlayerInteraction.type == 'map' ? (
        <div className="map-gui">
            <div className="map-container">
                {
                    currentPlayerInteraction.data.levels.filter((level)=> !level.sceneStateCondition || gameController.gameState.sceneState[level.sceneStateCondition]).map((level) => (
                    <button onClick={()=>{onMapPointClicked(level.name)}} className={`map-point ${level.name == currentLevel ? 'current' : ''}`} style={{left: `calc(50% + ${locations[level.name].position[0]}%)`, top: `calc(50% + ${locations[level.name].position[1]}%)`}} key={level.name}>
                        <span>{locations[level.name].name}</span>
                        {level.name == currentLevel && <img className="player-avatar" src={PlayerAvatarImage} />}
                    </button>
                    ))
                }
                <img src={MapImage} alt="map" />
            </div>
        </div>
    ) : null;
        
}