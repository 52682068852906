import { AudioController } from "./AudioController.ts";
import { NonPlayerCharacterController } from './NonPlayerCharacterController.ts';
import levels from "../assets/levels.js";
import quests from "../assets/quests.js";
import GameInteractionController from "./GameInteractionController.ts";
import PlayerController from "./PlayerController.ts";
import { elements } from "../assets/elements.js";

export type GameState = {
    status: "menu" | "loading" | "playing" | "paused" | "finished";
    sceneKey: string | null;
    currentScene: any;
    sceneState: any;
    currentDiscussion: any,
    currentComputerSession: any,
    currentQuest: any,
    characters: any,
    currentCharacter: any,
    currentPlayerHint?: string | null,
    currentPlayerInteraction?: any
};

type EventType = 'game:updated' | 'quest:completed' | 'scene:updated' | 'scene:loaded' | 'player:interacted' | 'currentPlayerInteraction:updated' | 'game:status:updated' | 'task:completed';
type EventHandlerFunction = (event: EventType, game: GameState) => boolean | void;
export type EventHandler = {
    key: string,
    priority?: number, // Some event needs to be handled before others, like looting
    handler: EventHandlerFunction
};
type EventHandlerDictionary = {[key in EventType]: EventHandler[]};

export class GameController {

    audio: AudioController;
    interaction: GameInteractionController
    levels: any = levels;
    player: PlayerController;

    eventHandlers: EventHandlerDictionary = {
        "game:updated": [],
        "quest:completed": [],
        "scene:updated": [],
        "scene:loaded": [],
        "player:interacted": [],
        "currentPlayerInteraction:updated": [],
        "game:status:updated": [],
        "task:completed": []
    };
    gameState: GameState = {
        status: "menu",
        sceneKey: null,
        currentScene: null,
        sceneState: {
            hasComputer: false
        },
        currentDiscussion: null,
        currentComputerSession: null,
        currentQuest: null,
        characters: {
            developer: new NonPlayerCharacterController('developer'),
            boss: new NonPlayerCharacterController('boss'),
            teacher: new NonPlayerCharacterController('teacher'),
            salesman: new NonPlayerCharacterController('salesman'),
            clerk: new NonPlayerCharacterController('clerk')
        },
        currentPlayerHint: null,
        currentPlayerInteraction: null,
        currentCharacter: null
    };

    constructor() {
        this.gameState.currentScene = levels[this.gameState.sceneKey];
        this.audio = new AudioController();
        //If new game
        this.gameState.currentQuest = quests[0];
        this.interaction = new GameInteractionController(this);
        this.player = new PlayerController(this);
    }

    addEventHandler(events: string[], listenerKey: string, eventHandler: EventHandlerFunction, priority?: number){
        events.forEach((event) => {
            if (!this.eventHandlers[event]) {
                this.eventHandlers[event] = [];
            }
            const alreadyExists = this.eventHandlers[event].find((listener) => listenerKey === listener.key);
            if(alreadyExists) return;
            this.eventHandlers[event].push({key: listenerKey, handler: eventHandler, priority: priority ?? 0} as EventHandler);
            this.eventHandlers[event] = this.eventHandlers[event].sort((a, b) => b.priority - a.priority);
        });
    }

    removeEventHandler(events: string[], listenerKey: string, eventHandler: EventHandlerFunction){
        events.forEach((event) => {
            if (!this.eventHandlers[event]) return;
            this.eventHandlers[event] = this.eventHandlers[event].filter((listener) => { return listener.key != listenerKey });
            this.eventHandlers[event] = this.eventHandlers[event].sort((a, b) => b.priority - a.priority);
        });
    }

    remoteAllEventHandlersForType(event) {
        if (!this.eventHandlers[event]) return;
        this.eventHandlers[event] = [];
    }

    sendEvent(event: string, data?: any){
        if(!this.eventHandlers[event]) return;
        // Some helps to break the loop if a handler returns true
        this.eventHandlers[event].some((listener) => {
            return listener.handler(event, data ?? this.gameState);
        });
    };

    loadScene(key: string) {
        try {
            this.gameState.sceneKey = key;
            this.gameState.currentScene = levels[key];
            //this.gameState.sceneState = {}; // Why is this here?
            this.setSceneState(key + ":travelled");
            this.remoteAllEventHandlersForType("player:interacted");
            this.clearPlayerHint();
            this.sendEvent("scene:loaded");
        } catch (e) {
            console.error(e);
        }
    }

    addElementToScene(key: string, position: number[]) {
        const element = elements[key];
        const pos = this.player.reference.nextTranslation();
        this.gameState.currentScene.elements.push({
            ...element,
            // [TO-DO] : Position behind the player + some random
            position: [pos.x - 0.5, pos.y, pos.z - 0.5]
        });
        this.sendEvent("scene:updated");
    }

    startDiscussion(npcId: string){
        
        this.audio.playAudio('conversation',1, 1.5);
        setTimeout(() => {
            this.gameState.currentDiscussion = this.gameState.characters[npcId].startDiscussion();
            this.gameState.currentCharacter = this.gameState.characters[npcId];
            if(this.gameState.currentDiscussion.sceneState){
                this.setSceneState(this.gameState.currentDiscussion.sceneState);
                this.validateQuestTaskBySceneState(this.gameState.currentDiscussion.sceneState);
            }
            this.sendEvent("game:updated");
        }, 500);
    }

    leaveDiscussion() {
        this.gameState.currentDiscussion = null;
        this.gameState.currentCharacter = null;
        this.sendEvent("game:updated");
    }

    setCurrentPlayerInteraction(type: string, data: any) {
        this.gameState.currentPlayerInteraction = {
            type,
            data
        };
        this.sendEvent("currentPlayerInteraction:updated");
    }

    answerDiscussion(optionIndex: number) {
        const next = this.gameState.currentDiscussion.options[optionIndex].next;
        //Validates on Player answer
        if (this.gameState.currentDiscussion.options[optionIndex].sceneState) {
            this.setSceneState(this.gameState.currentDiscussion.options[optionIndex].sceneState);
            this.validateQuestTaskBySceneState(this.gameState.currentDiscussion.options[optionIndex].sceneState);
        }
        if (this.gameState.currentDiscussion.options[optionIndex].interaction) {
            this.setCurrentPlayerInteraction(this.gameState.currentDiscussion.options[optionIndex].interaction.type, this.gameState.currentDiscussion.options[optionIndex].interaction.data);
            this.leaveDiscussion();
            return;
        }
        if (next === null) {
            this.leaveDiscussion();
        } else {
            const discussion = this.gameState.currentCharacter.nextDiscussion(next);
            this.gameState.currentDiscussion = discussion;
            //Validates on NPC answer
            if (this.gameState.currentDiscussion.sceneState) {
                this.setSceneState(this.gameState.currentDiscussion.sceneState);
                this.validateQuestTaskBySceneState(this.gameState.currentDiscussion.sceneState);
            }
        }
        this.sendEvent("game:updated");
    }

    validateQuestTaskBySceneState(sceneStateKey: string) {
        const task = this.gameState.currentQuest.tasks.find((task) => task.sceneState === sceneStateKey);
        if (task) {
            this.validateQuestTaskByTitle(task.title);
        }
    }

    validateQuestTaskByTitle(taskTitle: string) {
        const task = this.gameState.currentQuest.tasks.find((task) => task.title === taskTitle);
        if (task) {
            task.completed = true;
            this.tryValidateQuest();
            //this.sendEvent("game:updated");
            this.sendEvent("task:completed", task);
        }
    }

    tryValidateQuest() {
        const completed = this.gameState.currentQuest.tasks.every((task) => task.completed);
        if (completed) {
            const nextQuest = this.gameState.currentQuest.next;
            this.player.addExperience(this.gameState.currentQuest.experience);
            this.gameState.currentQuest = quests.find((quest) => quest.key === nextQuest);
            this.sendEvent("game:updated");
            this.sendEvent("quest:completed");
        }
    }

    startComputerSession() {
        this.gameState.currentComputerSession = {};
        this.sendEvent("game:updated");
    }

    leaveComputerSession() {
        this.gameState.currentComputerSession = null;
        this.sendEvent("game:updated");
    }

    getGameState() {
        return this.gameState;
    }

    getAudioController() {
        return this.audio;
    }

    setSceneState(stateKey: string) {
        this.gameState.sceneState[stateKey] = true;
        try {
            this.validateQuestTaskBySceneState(stateKey);
        } catch (e) {
            console.error(e);

        } finally {
            this.sendEvent("game:updated");
            this.sendEvent("scene:updated");
        }
    }

    setPlayerHint(hint: string) {
        this.gameState.currentPlayerHint = hint;
        this.sendEvent("game:updated");
    }

    clearPlayerHint() {
        this.gameState.currentPlayerHint = null;
        this.sendEvent("game:updated");
    }

    setGameStatus(status: GameState["status"]) {
        this.gameState.status = status;
        this.sendEvent("game:status:updated");
    }

    takeItem(uid: string, id: string, data: any){
        this.setSceneState(data.sceneState);
        this.player.inventory[uid] = {id, data};
        if(id === 'enveloppe'){
            this.setCurrentPlayerInteraction("letter", data.content);
            this.audio.playAudio('enveloppe',0.5, 1.5);
        } else if(id === 'book'){
            this.setCurrentPlayerInteraction("book", data.content);
            this.audio.playAudio('book',0.5);
        }else if(id === 'money'){
            this.player.addMoney(data.amount);
        } else if(id === 'soda'){
            this.player.drink();
        } else if(id === 'snack'){
            this.player.eat();
        } else if(id === 'hotdog'){
            this.player.eat();
        } else {
            this.audio.playAudio('loot',0.2);
        }
        console.log("INVENTORY", this.player.inventory);
    }

}
