import { Box, PerspectiveCamera, Plane, useVideoTexture } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useEffect, useState } from 'react';
import { MathUtils, Vector3 } from 'three';
import { useGameController } from './hooks/useGameController.ts';
import { useFollowCam } from 'ecctrl';

export default function ProjectorScreen({position, rotation, scale, data}){
    const gameController = useGameController();
    const [active, setActive] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const videoOptions = { muted: false, start: false};
    const video = useVideoTexture(data.video, videoOptions);
    const [texture, setTexture] = useState(video);
    const [unrollScale, setUnrollScale] = useState(0.1);
    const maxUnroll = 1.2;

    function onCourseLeave(event, data){
        if(gameController.gameState.currentPlayerInteraction.type === null){
            setActive(false);
            setShowVideo(false);
            gameController.audio.playAudio(gameController.gameState.currentScene.scene.backgroundMusic, 0.3);
            texture.image.pause();
        }
    }

    useEffect(()=>{
        
        gameController.setCurrentPlayerInteraction("course", {});
        setActive(true);
        setTimeout(()=>{
            texture.image.play();
            gameController.audio.pauseAudio(gameController.gameState.currentScene.scene.backgroundMusic);
            setShowVideo(true);
        },2000)

        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'projector-screen', onCourseLeave);

        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'projector-screen', onCourseLeave);
        }
    },[]);


    useFrame(()=>{
        if(gameController.gameState.currentPlayerInteraction){
            setUnrollScale(MathUtils.lerp(unrollScale, active ? maxUnroll : 0.1,0.05));
        }
    });

    return (
        <group rotation={rotation} position={position} scale={scale}>
            { active ? <PerspectiveCamera makeDefault position={[-0,0,5]} rotation={[0,0,0]} fov={25} aspect={16/9} near={0.1} far={1000} /> : null }
            
            <Box scale={[2.3,0.06,0.06]} position={[0,(maxUnroll/2) + 0.03,-0.03]}>
                <meshBasicMaterial color="darkgrey" />
            </Box>
            <Box scale={[0.03,0.08,0.08]} position={[-1.17,(maxUnroll/2) + 0.035,-0.031]}>
                <meshBasicMaterial color="grey" />
            </Box>
            <Box scale={[0.03,0.08,0.08]} position={[1.17,(maxUnroll/2) + 0.035,-0.031]}>
                <meshBasicMaterial color="grey" />
            </Box>
            <Box scale={[2.3,unrollScale,0.03]} position={[0,(maxUnroll/2) - (unrollScale/2),-0.03]} />
            <Box scale={[2.4,0.04,0.04]} position={[0,-unrollScale +0.6,-0.031]}>
                <meshBasicMaterial color="grey" />
            </Box>
            { showVideo ?
            <Plane scale={new Vector3(2,1,2)} position={[0,0,0]}>
                <meshBasicMaterial map={texture} toneMapped={false} />
            </Plane> : null}
        </group>
    );
}