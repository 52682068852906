import React, { useEffect, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";

export function SleepGUI() {
    const [show, setShow] = useState(false);

    const gameController = useGameController();
    useEffect(() => {
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'sleep-gui', () => {
            // Delay to let the user see the scene changing
            if(gameController.gameState.currentPlayerInteraction.type !== 'sleep') return;
            setTimeout(() => {
                setShow(true);
                setTimeout(() => {
                    setShow(false);
                }, 4500);
            }, 0);
        });
    }, []);
    return show ? (
        <div className="sleep-gui">
            <p>ZZzzz</p>
        </div>
    ) : null;
}
