import React, { useEffect, useState } from "react";
import '../assets/css/discussion.css';
import { useGameController } from "../hooks/useGameController.ts";

export default function DiscussionGUI(){
    
    const gameController = useGameController();
    const [currentDiscussion, setCurrentDiscussion] = useState(gameController.getGameState().currentDiscussion);
    const [currentCharacter, setCurrentCharacter] = useState(gameController.getGameState().currentCharacter);
    const [animatedText, setAnimatedText] = useState('');
    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentDiscussion(newGameState.currentDiscussion);
            setCurrentCharacter(newGameState.currentCharacter);
        };
        gameController.addEventHandler(["game:updated"], 'discussion-ui', handler);
        return () => {
            gameController.removeEventHandler(["game:updated"], 'discussion-ui', handler);
        };
    }, []);

    useEffect(() => {
        if(currentDiscussion){
            gameController.audio.playAudio('voice', 1, 1.0, true);
            let text = currentDiscussion.text;
            let i = 0;
            let interval = setInterval(() => {
                if(i < text.length){
                    setAnimatedText(text.substring(0, i+1));
                    i++;
                } else {
                    clearInterval(interval);
                    setTimeout(() => gameController.audio.pauseAudio('voice'), 500);
                }
            }, 10);
            return () => {
                clearInterval(interval);
            };
        }
    }, [currentDiscussion]);

    // @ts-ignore
    return currentDiscussion ?
    (   <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', zIndex: 4}}>
            
            <div style={{position: 'absolute', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0}}>
            </div>
        
            <div className="discussion-ui">
                <div className="discussion-avatar">
                    <img src={currentCharacter.getAvatar()} alt="avatar"/>
                </div>
                <div className="discussion-content">
                    <div className="discussion-name">
                        {currentCharacter.getName()}
                    </div>
                    <div className="discussion-text">
                        <p>{animatedText}</p>
                    </div>
                    <div className="discussion-options">
                        {currentDiscussion.options.map((option, index) => (
                            <button key={index} className={index == 0 ? 'main' : ''} onClick={() => gameController.answerDiscussion(index)}>{option.text}</button>
                        ))}
                        {
                            currentDiscussion.canLeave ? 
                            <button className="danger" onClick={() => gameController.leaveDiscussion()}>Leave</button> :
                            null
                        }
                    </div>
                </div>
            </div>

        </div>
        </>
    ) : null;
        
}