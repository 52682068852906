import React, { useEffect } from "react";
import { useGameController } from "./hooks/useGameController.ts";
import { Box, Sky } from "@react-three/drei";
import { useFrame, useLoader } from "@react-three/fiber";
import { Color, Vector3 } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import Fire from "./effects/Fire.tsx";

export default function MenuScene() {

    const gameController = useGameController();
    const gltf = useLoader(GLTFLoader, "data/models/menu.gltf");

    useEffect(()=>{
        gameController.getAudioController().playAudio("background",0.2,1,true);
        gameController.getAudioController().playAudio("fire",1,1,true);
        return () => {
            gameController.getAudioController().pauseAudio("background");
            gameController.getAudioController().pauseAudio("fire");
        }
    },[])

    useFrame(({ camera }) => {
        //rotate camera around the center
        const radius = 5;
        camera.position.set(Math.cos(Date.now() * 0.0002) * radius,3,Math.sin(Date.now() * 0.0002) * radius);
        camera.lookAt(1,1,-1);
        camera.updateProjectionMatrix();
    });
    return (
        <>
            
            <ambientLight intensity={Math.PI / 2}/>
            <spotLight position={[0, 100, 0]} angle={0.15} penumbra={1} decay={0} intensity={Math.PI} castShadow/>
            <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
            <Fire position={[0.35, 0.35, -1.2]} />
            <primitive object={gltf.scene} receiveShadow castShadow/>
        </>
    );
}