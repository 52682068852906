import React, { useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber'
/* @ts-ignore */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CuboidCollider } from '@react-three/rapier';
import { useGameController } from './hooks/useGameController.ts';

const models = {
    laptop: "data/models/Laptop/laptop.gltf",
}

export default function Prop({ id, position, scale, rotation}) {
    const gltf = useLoader(GLTFLoader, models[id])
    
    return (
        <primitive position={position} rotation={rotation} object={gltf.scene} scale={scale}/>
    );
  
};