import React, { Suspense, useEffect, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Physics } from '@react-three/rapier';
import { Loader, OrbitControls, PerspectiveCamera, useProgress } from "@react-three/drei";
import { EcctrlJoystick } from "ecctrl";

import Game from './Game.tsx';
import ComputerGUI from './ui/ComputerGUI.tsx';
import DiscussionGUI from './ui/DiscussionGUI.tsx';
import QuestGUI from './ui/QuestGUI.tsx';
import { QuestSuccessOverlay } from './ui/QuestSuccessOverlay.tsx';
import PlayerHintGUI from './ui/PlayerHintGUI.tsx';
import LetterGUI from './ui/LetterGUI.tsx';
import SpaceInvaderGUI from './ui/SpaceInvaderGUI.tsx';
import { useGameController } from './hooks/useGameController.ts';
import { useUtils } from './hooks/useUtils.ts';
import Menu from './Menu.tsx';
import MenuScene from './MenuScene.tsx';
import { Vector3 } from 'three';
import BookGUI from './ui/BookGUI.tsx';
import PlayerGUI from './ui/PlayerGUI.tsx';
import { usePlausible } from './hooks/usePlausible.ts';
import MapGUI from './ui/MapGUI.tsx';
import LoginModal from './ui/LoginModal.tsx';
import { useAuthStore } from './stores/auth.ts';
import CourseGUI from './ui/CourseGUI.tsx';
import { SleepGUI } from './ui/SleepGUI.tsx';
import ShopGUI from './ui/ShopGUI.tsx';


export default function App() {
  const { mobileAndTabletCheck } = useUtils();
  const plausible = usePlausible();
  const { active, progress, errors, item, loaded, total } = useProgress()
  const gameController = useGameController();
  const [gameStatus, setGameStatus] = useState(gameController.getGameState().status);
  const authState = useAuthStore();
  const cameraRef = React.useRef();
  useEffect(()=>{
    authState.load();
    function handler (event, gameState) {
      setGameStatus(gameState.status);
    }
    function analyticsHandler(event, data){
      if(plausible){
        if(event == "game:status:updated" || event == "scene:loaded"){
          plausible("SceneLoaded", {props: {scene: data.sceneKey}});
        } else if (event == "task:completed"){
          plausible("TaskCompleted", {props: {task: data.title}});
        }
        
      }
    }
    gameController.addEventHandler(["game:status:updated"], 'app', handler);
    gameController.addEventHandler(["scene:loaded", "game:status:updated", "task:completed"], 'app', analyticsHandler);

    return ()=>{
      gameController.removeEventHandler(["game:status:updated"],'app', handler);
      gameController.removeEventHandler(["scene:loaded", "game:status:updated", "task:completed"],'app', analyticsHandler);
    }
  }, []);
  
  function onCanvasClick(){
    if(mobileAndTabletCheck()){
      gameController.sendEvent('player:interacted');
    }
  }
  
  return (
    <>
    <div id="demo">Demo</div>
    <LoginModal/>
    { gameStatus === 'menu' ? 
      (progress == 100 ? <Menu/> : null) : 
      <>
        <PlayerGUI/>
        <CourseGUI/>
        <QuestSuccessOverlay/>
        <QuestGUI/>
        <ComputerGUI/>
        <DiscussionGUI/>
        <PlayerHintGUI/>
        <ShopGUI/>
        <LetterGUI/>
        <BookGUI/>
        <SpaceInvaderGUI/>
        <MapGUI/>
        <SleepGUI/>
        { mobileAndTabletCheck() ? <EcctrlJoystick /> : null }
      </> 
    }
      <Canvas onClick={()=>onCanvasClick()} shadows style={{backgroundColor: gameStatus === 'menu' && progress == 100 ? '#3498db' : 'transparent'}}>
        <Suspense>
        <Physics>
          { gameStatus === 'menu' ? <MenuScene/> : <Game camera={cameraRef}/> }
        </Physics>
        </Suspense>
        {/*
            Use a PerspectiveCamera.
            PerspectiveCameras work like real works cameras
            and provide depth perception.
          */}
          <PerspectiveCamera ref={cameraRef}  makeDefault position={[1,3,1]}/>
          {/*
            This lets you rotate the camera.
            We've associated our React ref with it
            like we would do for any React component
          */}
          { /*<OrbitControls />*/}
      </Canvas>
      <Loader initialState={(active) => active}/>
      </>)}
