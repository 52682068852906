import React, { useEffect, useState } from "react";
import '../assets/css/discussion.css';
import { useGameController } from "../hooks/useGameController.ts";

export default function DiscussionGUI(){
    
    const gameController = useGameController();
    const [currentHint, setCurrentHint] = useState(gameController.getGameState().currentPlayerHint);
    const [moneyChange, setMoneyChange] = useState(0);
    function onHintUpdated (event, newGameState) {
        setCurrentHint(newGameState.currentPlayerHint);
    };

    function onMoneyUpdated (event, data: {amount:number, total:number}) {
        setMoneyChange(data.amount);
        setTimeout(()=>{
            setMoneyChange(0);
        }, 2000);
    };

    useEffect(() => {
        
        gameController.addEventHandler(["game:updated"], 'player-hint-gui', onHintUpdated);
        gameController.addEventHandler(["player:money:updated"], 'player-hint-gui', onMoneyUpdated);
        return () => {
            gameController.removeEventHandler(["game:updated"], 'player-hint-gui', onHintUpdated);
            gameController.removeEventHandler(["player:money:updated"], 'player-hint-gui', onMoneyUpdated);
        };
    }, []);

    // @ts-ignore
    return (
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent', zIndex: 1, pointerEvents: 'none'}}>
            
            { currentHint ? <div className="player-hint">{currentHint}</div> : null }
            { moneyChange != 0 ? <div className="money-effect">{moneyChange}$</div> : null }
        </div>
      
    );
        
}