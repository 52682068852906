export default [
    {
        id: "introduction",
        title: "Before school",
        questions: [
            {
                "title": "Which of the following is a programming language?",
                "options": [
                    "Boa",
                    "Python",
                    "Cobra",
                    "Anaconda"
                ],
                "answer": "Python",
                "explanation": "Python is one of the most popular programming languages for maths and AI."
            },
            {
                "title": "What does a compiler do?",
                "options": [
                    "Translate code to be understood by a machine",
                    "Compress data into zip files",
                    "Connect two devices through the internet",
                    "None of the above"
                ],
                "answer": "Translate code to be understood by a machine",
                "explanation": "It takes the code you write and translates it into a language that the computer and its processor can understand."
            }
        ]
    },
]