import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import type {} from '@redux-devtools/extension' // required for devtools typing

interface ModalState {
  loginModal?: any
}

export const useModalStore = create<ModalState>()(
  devtools(
      (set) => ({
        loginModal: false,
        showLoginModal: () => set({ loginModal: true }),
        hideLoginModal: () => set({ loginModal: false }),
      }),
      {
        name: 'modal-storage',
      },
  ),
)