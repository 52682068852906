import React, { useEffect, useState } from 'react';
import { useLoader } from '@react-three/fiber';
/* @ts-ignore */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGameController } from './hooks/useGameController.ts';
import { MeshCollider, RigidBody } from '@react-three/rapier';
import { useFBX } from '@react-three/drei';
import Wall from './Wall.tsx';

export default function Scene({ isActive, model, collider, music}) {
    const gameController = useGameController();
    const rb = React.useRef();
    const [gltf, setGltf] = useState(useLoader(GLTFLoader, model));
    const [fbx, setFbx] = useState(useFBX(collider));
    const walls = Object.values(gltf.nodes).filter((child) => child.name.indexOf("Wall") > -1);
    fbx.traverse((child) => {
        if (child.isMesh) {
          child.material.visible = false
        }
    });

    useEffect(()=>{
    }, []);

    useEffect(()=>{
        if(!isActive) {
            gameController.getAudioController().pauseAudio(music);
        } else {
            gameController.getAudioController().playAudio(music, 0.2, 1, true);
        }
        return ()=>{
            gameController.getAudioController().pauseAudio(music);
        }
    }, [isActive]);

    return isActive ? (
        <group>
            <primitive position={[0,0,0]} object={gltf.scene} scale={[1.0,1.0,1.0]} receiveShadow castShadow dispose={null}/>
            { gltf.scene ? <Wall walls={walls} /> : null }
            <RigidBody colliders="hull" type={'fixed'}  scale={[0.01,0.01,0.01]}>
                <primitive object={fbx}/>
            </RigidBody>
        </group>
    ) : null
  
};