import React, { useEffect, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";
import Markdown from 'react-markdown';
import '../assets/css/letter-gui.css';
import rehypeRaw from 'rehype-raw';
import CloseIcon from '../assets/img/icons/close.svg';

export default function LetterGUI(){
    const [currentInteraction, setCurrentInteraction] = useState(null);
    const gameController = useGameController();

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'letter-gui', handler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'letter-gui', handler);
        }
    }, []);

    function close(){
        gameController.setCurrentPlayerInteraction(null, null);
    }

    return currentInteraction && currentInteraction.type === 'letter' ?
    (   <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}>
            <div onClick={()=>{gameController.setCurrentPlayerInteraction(null, null)}} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            </div>
        
            <div className={'letter'} style={{position: 'relative', zIndex: 2,backgroundColor: 'white', maxWidth: '600px'}} onClick={()=>close()}>
                <Markdown rehypePlugins={[rehypeRaw]}>{currentInteraction.data}</Markdown>
                <button className="btn danger lg" style={{position: 'absolute', top:-20, right: -20, width: 60}} onClick={()=>close()}>
                    <img src={CloseIcon} style={{height: 20}} />
                </button>
            </div>
        </div>
        </>
    ) : null;
        
}