import React, { useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber'
/* @ts-ignore */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CuboidCollider } from '@react-three/rapier';
import { useGameController } from './hooks/useGameController.ts';
import { Outlines } from '@react-three/drei';

const models = {
    money: 'data/models/money.gltf',
    snack: 'data/models/Snack/snack.gltf',
    briefcase: 'data/models/briefcase.gltf',
    enveloppe: 'data/models/enveloppe.gltf',
    book: 'data/models/book.gltf',
    soda: 'data/models/soda.gltf',
    hotdog: 'data/models/hot_dog.gltf',
}

export default function Item({ uid, id, position, scale, initialRotation, data}) {
    const gameController = useGameController();
    const [active, setActive] = useState(true);
    const { scene, nodes, materials} = useLoader(GLTFLoader, models[id]);
    const [rotation, setRotation] = useState([0,0,0]);

    useFrame(() => {
        if(active) {
            setRotation([rotation[0], rotation[1] + 0.01, rotation[2]]);
        }
    });

    function destroy(){
        setActive(false);
        onIntersectionExit();
    }

    function onInteraction(){
        gameController.takeItem(uid, id, data);
        destroy();
        return true;
    }

    function onIntersectionEnter(){
        gameController.setPlayerHint("(E) Take")
        gameController.addEventHandler(["player:interacted"], uid, onInteraction, 1);
    }

    function onIntersectionExit(){
        gameController.clearPlayerHint();
        gameController.removeEventHandler(["player:interacted"], uid, onInteraction);
    }
    
    return active && (
        <group rotation={rotation} 
        position={position} >
            <CuboidCollider 
                args={[0.2, 0.2, 0.2]} 
                sensor 
                onIntersectionEnter={onIntersectionEnter}
                onIntersectionExit={onIntersectionExit}
                >

                <mesh scale={scale} geometry={scene.children[0].geometry} rotation={initialRotation} material={Object.values(materials)[0]}>
                    <Outlines  thickness={2.5} color="white" />
                </mesh>
            </CuboidCollider>
        </group>
    );
  
};