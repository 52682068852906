import { discussions } from '../assets/discussions.js';

const names = {
    boss: "Company's boss",
    developer: 'Developer',
    player: 'Player',
    teacher: 'Teacher',
    salesman: 'Salesman',
    clerk: 'Clerk',
};

export class NonPlayerCharacterController {
    id: string;
    lastDiscussionKey: string = 'start';
    constructor(id: string) {
        this.id = id;
    }

    getAvatar(){
        return `/data/characters/portrait_${this.id}.png`;
    }

    getName(){
        return names[this.id];
    }

    startDiscussion(){
        return discussions[this.id][this.lastDiscussionKey];
    }

    nextDiscussion(key: string){
        this.lastDiscussionKey = key;
        return  discussions[this.id][key];
    }
};