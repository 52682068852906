// @ts-ignore
import ShopAudio from '../assets/audio/shop.mp3';
import BedroomAudio from '../assets/audio/bedroom.mp3';
import BackgroundAudio from '../assets/audio/background.mp3';
import OfficeAudio from '../assets/audio/office.mp3';
import LootAudioEffect from '../assets/audio/effect_loot.wav';
import WalkAudioEffect from '../assets/audio/effect_walk.mp3';
import ComputerAudioEffect from '../assets/audio/effect_computer.mp3';
import SuccessAudioEffect from '../assets/audio/effect_success.mp3'
import ConversationAudioEffect from '../assets/audio/effect_conversation.mp3';
import UnpackAudioEffect from '../assets/audio/effect_unpack.mp3';
import FireAudioEffect from '../assets/audio/effect_fire.mp3';
import EnveloppeAudioEffect from '../assets/audio/effect_enveloppe.mp3';
import BookAudioEffect from '../assets/audio/effect_book.mp3';
import MoneyAudioEffect from '../assets/audio/effect_money.mp3';
import EatAudioEffect from '../assets/audio/effect_eat.mp3';
import DrinkAudioEffect from '../assets/audio/effect_drink.mp3';
import BuyAudioEffect from '../assets/audio/effect_buy.mp3';
import FailAudioEffect from '../assets/audio/effect_fail.mp3';
import PeeAudioEffect from '../assets/audio/effect_pee.mp3';
import SleepAudioEffect from '../assets/audio/effect_sleep.mp3';
import AlarmAudioEffect from '../assets/audio/effect_alarm.mp3';
import VoiceAudioEffect from '../assets/audio/effect_voice.mp3';

export class AudioController {

    players: any = {};
    isMuted = false;

    audioLibrary = {
        background: BackgroundAudio,
        shop: ShopAudio,
        bedroom: BedroomAudio,
        office: OfficeAudio,
        loot: LootAudioEffect,
        walk: WalkAudioEffect,
        computer: ComputerAudioEffect,
        success: SuccessAudioEffect,
        conversation: ConversationAudioEffect,
        unpack: UnpackAudioEffect,
        fire: FireAudioEffect,
        enveloppe: EnveloppeAudioEffect,
        book: BookAudioEffect,
        money: MoneyAudioEffect,
        eat: EatAudioEffect,
        drink: DrinkAudioEffect,
        buy: BuyAudioEffect,
        error: FailAudioEffect,
        pee: PeeAudioEffect,
        sleep: SleepAudioEffect,
        alarm: AlarmAudioEffect,
        voice: VoiceAudioEffect
    };

    constructor(){
        for(let key in this.audioLibrary){
            let audio = new Audio(this.audioLibrary[key]);
            audio.loop = false;
            this.players[key] = audio;
        }
    }

    playAudio(key: string, volume?: number, speed?: number, loop?: boolean){
        try {
            let audio = this.players[key];
            audio.volume = volume || 1;
            audio.playbackRate = speed || 1;
            audio.loop = loop || false;
            audio.play();
        } catch (error) {
            console.log(error);
        }
    }

    pauseAudio(key: string){
        try {
            this.players[key].pause();
        } catch (error) {
            console.log(error);
        }
    }

    destroy(){
        for(let key in this.players){
            this.players[key].pause();
            this.players[key] = null;
        }
    }
}