import "../assets/css/computer.css";
import { useGameController } from "../hooks/useGameController.ts";
import Quiz from "./Quiz.tsx";
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw'
import PathImage from '../assets/img/path.svg';
import BackIcon from '../assets/img/icons/back.svg';
import CodeIcon from '../assets/img/icons/code.svg';
import LockIcon from '../assets/img/icons/lock.svg';

import quizData from '../assets/quiz.js';
import { courses as coursesData }  from '../assets/courses.js';
import { missions as missionsData } from '../assets/missions.js';

import { useEffect, useState } from "react";

import CoursesIcon from '../assets/img/apps/courses.svg';
import QuizIcon from '../assets/img/apps/quiz.svg';
import MissionsIcon from '../assets/img/apps/missions.svg';
import CodeChallenge from "./CodeChallenge.tsx";

const icons = {
    courses: CoursesIcon,
    quiz: QuizIcon,
    missions: MissionsIcon
}


export function Computer({defaultTab = 'courses', disabledTabs = []}){

    const [currentQuiz, setCurrentQuiz] = useState(null);
    const [currentMission, setCurrentMission] = useState(null);
    const [courses, setCourses] = useState(coursesData);
    const [quizzes, setQuizzes] = useState(quizData);
    const [missions, setMissions] = useState(missionsData);

    const [currentCourse, setCurrentCourse] = useState(null);
    const [currentModule, setCurrentModule] = useState(null);

    const [currentTab, setCurrentTab] = useState(defaultTab);
    const [tabs, setTabs] = useState({"courses": true, "quiz": true, "missions": true});

    const gameController = useGameController();

    useEffect(() => {
        setCurrentTab(gameController.gameState.currentPlayerInteraction.data?.defaultTab ?? 'courses');
        const t = {...tabs};
        console.log(gameController.gameState.currentPlayerInteraction.data);
        Object.keys(t).forEach(key => {
            t[key] = disabledTabs?.indexOf(key) > -1 ? false : true;
        });
        setTabs(t);
    },[]);

    function onSuccess(){
        setQuizzes(quizzes.map(quiz => {
            if(quiz.id === currentQuiz.id){
                quiz.status = 'completed';
            }
            return quiz;
        }));
        gameController.setCurrentPlayerInteraction(null, null);
        gameController.setSceneState("quiz:success");
    }    



    function onChallengeSuccess(){
        gameController.setSceneState("challenge:succeeded");
        gameController.setCurrentPlayerInteraction(null, null);
    }


    function QuizList(){
        return (
            <section>
                <p className="window-title">Quiz</p>
                <div className="quiz-list">
                    {
                        quizzes.map((value, index) => {
                            return (
                                <button onClick={()=>setCurrentQuiz(value)} className="quiz-thumbnail-container" key={index}>
                                    <span className="quiz-id">#{index+1}</span>
                                    <div className="quiz-thumbnail">
                                    <span className="quiz-title">{value.title}</span>
                                    { 
                                        value.status === 'completed' ?
                                        <span className="quiz-status"></span> :
                                        null
                                    }
                                    <span className="quiz-questions-count">{value.questions.length} questions</span>
                                    </div>
                                </button>
                            );
                        })
                    }

                    <div className="quiz-locked">
                        <img src={LockIcon}/>
                    </div>
                </div>
            </section>
                    
        );
    }

    function Module({module, back}){


        function validateModule(module){

            setCourses(courses.map(course => {
                course.modules = course.modules.map(m => {
                    if(m.id === module.id){
                        m.state = 'completed';
                    }
                    return m;
                });
                return course;
            }));
            back();
        }

        return (
            <section>
                <p className="window-title"><button className="btn icon" onClick={()=>back()}><img src={BackIcon}/></button>{module.title}</p>
                <div className="module-content">
                    <div className="markdown">
                        <Markdown rehypePlugins={[rehypeRaw]}>{module.content}</Markdown>
                    </div>
                    <div style={{textAlign:'center'}}>
                        <button className="btn secondary lg" onClick={()=>validateModule(module)}>Validate module</button>
                    </div>
                </div>
            </section>
        )
    }

    function ModulesList({course, onModuleSelected, back}){
        return (
            <section>
                <p className="window-title"><button className="btn icon" onClick={()=>back()}><img src={BackIcon}/></button>Modules</p>
                <div className="modules-list">
                    {
                        course.modules.map((module, index) => {
                            return (
                                <>
                                    <img style={{width: 30, marginTop: -20, marginBottom: -20}}  src={PathImage}/>
                                    <button onClick={()=>onModuleSelected(module)} className="module-thumbnail">
                                        <span className={`module-status ${module.state === 'completed' ? 'completed' : ''}`}></span>
                                        <div className="module-info">
                                            <span className="module-title">{module.title}</span>
                                        </div>
                                    </button>
                                </>
                            );
                        })
                    }
                </div>
            </section>
        );
    }

    function CourseList({onCourseSelected}){
        return (
            <section>
                <p className="window-title">Courses</p>
                <div className="courses-list" style={{padding: 10}}>
                    {
                        courses.map((course) => {
                            return (
                                <button onClick={()=>onCourseSelected(course)} className="course-thumbnail" disabled={course.id != 'introduction'}>
                                    <span className="course-title">{course.title}</span>
                                    <span className="course-difficulty">{course.difficulty} - {course.modules.length} modules</span>
                                    <img className="course-icon" src={course.icon}/>
                                </button>
                            );
                        })
                    }
                </div>
            </section>
        );
    }


    function CourseTab(){


        return currentCourse == null ? 
                <CourseList onCourseSelected={setCurrentCourse}/> :
                currentModule == null ?
                <ModulesList course={currentCourse} back={()=>{setCurrentCourse(null)}} onModuleSelected={setCurrentModule}/> :
                <Module module={currentModule} back={()=>{setCurrentModule(null)}}/>
            

    }

    function MissionTab(){
        return currentMission == null ?
                <MissionList/> :
                <Mission/>
    }


    function MissionList(){
        return (
            <section>
                <p className="window-title">Mission</p>
                <div className="missions-list">
                    {
                        missions.map((mission) => 
                            <button className="mission-thumbnail" key={mission.title} onClick={()=>setCurrentMission(mission)}>
                                <span className="mission-category">
                                    <img className="mission-category-icon" src={CodeIcon}/>
                                    Introduction
                                </span>
                                {mission.title}
                                <p className="mission-description">{mission.description}</p>
                                <span className="mission-difficulty">
                                    {
                                        new Array(3).fill(0).map((_, index) => <i key={index} className={index < mission.difficulty ? 'active' : ''} />)
                                    }
                                </span>
                            </button>
                        )
                    }
                </div>
            </section>
        )
    }

    function Mission (){
        return (
            <CodeChallenge challenge={currentMission} onChallengeSuccess={onChallengeSuccess}/>
        );
    }

    function MainWindow(){
        return (
            <div className="main-window">
                <div className="window-header">
                    <span>AdventureOS</span>
                </div>
                <div className="window-content">
                    <nav>
                        {
                            Object.entries(tabs).map(([key, value]) => {
                                return (
                                    <button className={`app ${currentTab == key ? 'active' : ''}`} key={key} disabled={!value} onClick={()=>setCurrentTab(key)}>
                                        <img className="app-icon" src={icons[key]}/>
                                        <span className="app-title">{key}</span>
                                    </button>
                                );
                            })
                        }
                    </nav>
                    {
                        currentTab === 'courses' ? <CourseTab/> :
                        currentTab === 'quiz' ? <QuizList/> :
                        currentTab === 'missions' ? <MissionTab/> :
                        null
                    }
                </div>
            </div>
        );
    
    }
    
    return (
        <div className="computer">
            { !currentQuiz ? <MainWindow/> : null }
            { currentQuiz ? <Quiz quiz={currentQuiz} onSuccess={onSuccess} /> : null }
        </div>
    );
}