import React, { useState } from 'react';
import Editor from '@monaco-editor/react';
import rehypeRaw from 'rehype-raw';
import '../assets/css/code-challenge.css';
import PlayIcon from '../assets/img/icons/play.svg';
import { useGameController } from '../hooks/useGameController.ts';
import Markdown from 'react-markdown';


export default function CodeChallenge({challenge, onChallengeSuccess}) {
    
    const gameController = useGameController();
    const [currentChallenge, setCurrentChallenge] = useState(challenge);
    const [currentChallengeOutputs, setCurrentChallengeOutputs] = useState([]);
    const [code, setCode] = useState(currentChallenge.sampleCode);

    function run(){
        setCurrentChallengeOutputs([]);
        let success = currentChallenge.testSuite.every((test)=>{
            try {
                const f = new Function('arg1', 'arg2', code + test.func);
                const r = f();
                setCurrentChallengeOutputs([...currentChallengeOutputs, r]);
                return r === test.expectedOutput;
            } catch(e){
                setCurrentChallengeOutputs([...currentChallengeOutputs, e.message]);
            }
        });
        if(success){
            onChallengeSuccess();
        }
    }

    function ChallengeResult(){
        return currentChallenge.testSuite.map((test, index) => {
            return (
                <div key={index} className='test-case'>
                    <p>Result</p>
                    <p>Expected output : {test.expectedOutput}</p>
                    <p>Output : {currentChallengeOutputs[index]}</p>
                </div>
            );
        });
    }

    return (
        <div className='challenge-container'>
            <div className='challenge-editor'>
                <div className='monaco-editor'>
                    <Editor 
                        height="100%" 
                        width={'100%'} 
                        defaultLanguage="javascript" 
                        defaultValue={code} 
                        onChange={(val)=>setCode(val)}
                        theme="vs-dark" 
                        options={{
                            fontSize: 16,
                            fontWeight: '400',
                            lineNumbers: 'off',
                            glyphMargin: false,
                            folding: false,
                            // Undocumented see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
                            lineDecorationsWidth: 0,
                            lineNumbersMinChars: 0,
                            minimap: {enabled: false}
                        }}
                    />
                </div>
            </div>

            
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'stretch', gap: 10, flex: 1}}>
                <div className='challenge-rules markdown'>
                    <Markdown rehypePlugins={[rehypeRaw]}>{currentChallenge.rules}</Markdown>
                </div>
                <button className='btn secondary lg' style={/*{position: 'absolute',width: 90, left: '50%', top: 'calc(40% - 50px)'}*/{width:' 100%'}} onClick={()=>run()}>
                    <img src={PlayIcon} style={{height: 20}}/> Run code
                </button>
                <div className='challenge-output'>
                    {
                        currentChallengeOutputs.length == 0 ? <p>Run your code to see results</p> : <ChallengeResult/>
                    }
                </div>
            </div>
        </div>
    );
}
