import CodeIcon from './img/icons/code.svg';

const modules = [
    {
        id: 0,
        title: "What is code?",
        content: `
> Computer code is the language that humans use to communicate with computers. 

It consists of a series of instructions that a computer can understand and execute to perform specific tasks. Just as we use different languages to communicate with each other, such as English, Spanish, or Chinese, computer code is written in various programming languages designed to be precise and unambiguous.

## How Does Code Work?

At its core, computer code converts human logic and commands into a format that a computer's central processing unit (CPU) can process. This conversion involves translating high-level instructions into low-level machine language, which the hardware understands. Programs are essentially a collection of these instructions that tell the computer how to perform a particular operation, like displaying a message on the screen, calculating a number, or storing data.
Importance of Code

Understanding computer code is essential because it underpins almost all modern technology. From smartphones and websites to cars and home appliances, code runs the software that makes these devices functional. Learning to code opens up a world of possibilities, allowing individuals to create applications, automate tasks, and solve problems efficiently.

## Tools for Coding

Computer code can be written and tested using various tools, including text editors and integrated development environments (IDEs). Debugging is an essential part of coding, as it involves identifying and fixing errors in the code to ensure it runs correctly. As you begin your journey into programming, remember that coding is not just about writing instructions; it's about solving problems and thinking logically. With practice and patience, anyone can learn to code and harness the power of computers to create innovative solutions.
        `
    },
    {
        id: 1,
        title: "Programming Languages",
        content: `
Programming languages are the various tools that programmers use to write computer code. Each language has its syntax and rules, making it suitable for different tasks and applications. Some of the most well-known programming languages include Python, JavaScript, Java, C++, and Ruby. Choosing the right programming language depends on the specific needs of the project and the programmer's familiarity with the language.
Popular Languages

    Python is a versatile and beginner-friendly language known for its readability and simplicity. It's widely used in web development, data analysis, artificial intelligence, and scientific computing.
    JavaScript is the backbone of web development, enabling interactive features on websites. It works alongside HTML and CSS to create dynamic web pages and applications.
    Java is a powerful, platform-independent language used in various applications, from web and mobile apps to large enterprise systems.
    C++ is an extension of the C programming language, offering object-oriented features. It's commonly used in system software, game development, and performance-critical applications.
    Ruby is known for its elegant syntax and is often used in web development, particularly with the Ruby on Rails framework.

Specialized Languages

Apart from these, there are many other specialized languages like Swift for iOS development, R for statistical analysis, and SQL for database management. Learning multiple languages can be beneficial, as it allows programmers to choose the best tool for a given task and enhances their overall coding skills.
Choosing a Language

The choice of programming language often depends on factors such as project requirements, development speed, community support, and personal preference. As you progress in your coding journey, you'll likely find yourself learning and using several languages, each offering unique features and advantages.
        `
    },
    {
        id: 2,
        title: "Variables",
        content: `
Variables are fundamental building blocks in programming. They act as storage containers for data, allowing programs to store, retrieve, and manipulate information. A variable is essentially a named space in memory where data can be stored. This data can be anything from numbers and text to more complex structures like lists and objects.
Declaring Variables

When you declare a variable, you typically assign it a name and a value. For example, in JavaScript, you might write:

javascript

let age = 25;

Here, age is the variable name, and 25 is its value. This means that wherever you use the name age in your code, the computer will refer to the value 25.

Changing Variable Values

Variables can change values as the program runs, which is why they're called "variables." For instance, if you write:

javascript

age = age + 1;

The value of age will increase by 1. This ability to change values is essential for creating dynamic and flexible programs.
Types of Variables

There are different types of variables depending on the type of data they hold. Common types include integers (whole numbers), floats (decimal numbers), strings (text), and booleans (true/false values). Some programming languages require you to specify the type of variable when you declare it, while others, like JavaScript, determine the type based on the assigned value.

Understanding variables is crucial because they allow you to write more complex and efficient code. They enable you to store user inputs, perform calculations, and manage data effectively. As you practice programming, you'll see how variables interact with other concepts like loops, functions, and data structures to build functional and sophisticated applications.
        `
    }
];

export const courses = [
    {
        id: "introduction",
        title: "Programming 101",
        icon: CodeIcon,
        modules: [
            modules[0],
            modules[1],
            modules[2],
        ],
        difficulty: "Easy"
    },
    {
        id: "javascript",
        title: "JavaScript",
        icon: CodeIcon,
        modules: [
        ],
        difficulty: "Easy"
    },
];