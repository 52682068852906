import React, { useEffect, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";
import Markdown from 'react-markdown';
import '../assets/css/book-gui.css';
import rehypeRaw from 'rehype-raw'

export default function BookGUI(){
    const [currentInteraction, setCurrentInteraction] = useState(null);
    const gameController = useGameController();
    const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'book-ui', handler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'book-ui', handler);
        }
    }, []);

    function previousPage(){
        setCurrentPage(currentPage-2);
        gameController.audio.playAudio('book',0.5, 1.5);
    }

    function nextPage(){
        setCurrentPage(currentPage+2);
        gameController.audio.playAudio('book',0.5, 1.5);
    }

    return currentInteraction && currentInteraction.type === 'book' ?
    (   <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}>
            <div onClick={()=>{gameController.setCurrentPlayerInteraction(null, null)}} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            </div>
        
            <div className={'book'} style={{position: 'relative', zIndex: 2,backgroundColor: 'white'}}>
                <div className="book-page" onClick={previousPage}>
                    <Markdown rehypePlugins={[rehypeRaw]}>{currentInteraction.data[currentPage] ?? '' }</Markdown>
                </div>
                <div className="book-center"></div>
                <div className="book-page" onClick={nextPage}>
                    <Markdown rehypePlugins={[rehypeRaw]}>{currentInteraction.data[currentPage+1] ?? '# The end'}</Markdown>
                </div>
            </div>
        </div>
        </>
    ) : null;
        
}