import React, { useEffect, useRef, useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber'
import { Box, KeyboardControls, useGLTF } from '@react-three/drei';
import { myPlayer } from 'playroomkit';
import Ecctrl, { EcctrlAnimation, useGame } from 'ecctrl';
import { useGameController } from './hooks/useGameController.ts';
import { TextureLoader, Vector3 } from 'three';


const keyboard = [
	{ name: "forward", keys: ["ArrowUp", "KeyW"] },
	{ name: "backward", keys: ["ArrowDown", "KeyS"] },
	{ name: "leftward", keys: ["ArrowLeft", "KeyA"] },
	{ name: "rightward", keys: ["ArrowRight", "KeyD"] },
	//{ name: "jump", keys: ["Space"] },
	{ name: "run", keys: ["Shift"] },
	// Optional animation key map
	{ name: "action1", keys: ["1"] },
	{ name: "action2", keys: ["2"] },
	{ name: "action3", keys: ["3"] },
	{ name: "action4", keys: ["KeyF"] },
  ];
  

//https://codesandbox.io/p/github/sliktrik/r3f-world-with-character/main?file=%2Fsrc%2Fcomponents%2FCharacter.tsx&workspace=%257B%2522activeFileId%2522%253A%2522clez6ybn4000gg9ghhqm11iya%2522%252C%2522openFiles%2522%253A%255B%2522%252FREADME.md%2522%255D%252C%2522sidebarPanel%2522%253A%2522EXPLORER%2522%252C%2522gitSidebarPanel%2522%253A%2522COMMIT%2522%252C%2522spaces%2522%253A%257B%2522clezdlos7000x3b6hfohnsuty%2522%253A%257B%2522key%2522%253A%2522clezdlos7000x3b6hfohnsuty%2522%252C%2522name%2522%253A%2522Default%2522%252C%2522devtools%2522%253A%255B%257B%2522key%2522%253A%2522clezdlos7000y3b6hn30qwah1%2522%252C%2522type%2522%253A%2522PROJECT_SETUP%2522%252C%2522isMinimized%2522%253Afalse%257D%252C%257B%2522type%2522%253A%2522PREVIEW%2522%252C%2522taskId%2522%253A%2522start%2522%252C%2522port%2522%253A3000%252C%2522key%2522%253A%2522clezdlos7000z3b6ha9izsakt%2522%252C%2522isMinimized%2522%253Afalse%257D%255D%257D%257D%252C%2522currentSpace%2522%253A%2522clezdlos7000x3b6hfohnsuty%2522%252C%2522spacesOrder%2522%253A%255B%2522clezdlos7000x3b6hfohnsuty%2522%255D%252C%2522hideCodeEditor%2522%253Afalse%257D
export default function Player({ initialPosition, initialRotation, scale }) {
	const gameController = useGameController();
	const characterURL = 'data/models/Player/player.gltf'
    const { scene, nodes, materials, animations } = useGLTF(characterURL);
	const currentAnimation = useGame((state)=>state.curAnimation);
	const [shouldCameraFollow, setShouldCameraFollow] = useState(true);
	const [isVisible, setIsVisible] = useState(true);

	//nudity
	const [nudityFilter, setNudityFilter] = useState(false);
	const [nudityIndex, setNudityIndex] = useState(0);
	let interval = 0;
	const nudityTextures = useLoader(TextureLoader, ["/data/textures/nudity-1.png", "/data/textures/nudity-2.png","/data/textures/nudity-3.png","/data/textures/nudity-4.png"]);

	const [keyboardMap, setKeyboardMap] = useState(keyboard);

	const animationsMap = {
		idle: "Idle",
		walk: "Walk",
		run: "Run",
		jump: "Idle",
		jumpIdle: "Idle",
		jumpLand: "Idle",
		fall: "Idle", // This is for falling from high sky
	};
	  
	const ref = useRef();
	const player = myPlayer();
	useFrame((state, delta) => {
		if(!ref.current || !player) return;
		player.setState("position", ref.current.nextTranslation());
		player.setState("rotation", ref.current.nextRotation());
		if(nudityFilter) {
			interval = interval+delta;
			if(interval > 0.5){
				setNudityIndex(nudityIndex+1);
				interval = 0;
			}
		}

	});

	function onPlayerCourse (event, data) {

		if(!gameController.gameState.currentPlayerInteraction.type){
			setKeyboardMap(keyboard);
		} else {
			setKeyboardMap([]);
		}

		if(gameController.gameState.currentPlayerInteraction.type == "course"){
			setShouldCameraFollow(false);
			setIsVisible(false);
			gameController.clearPlayerHint();
		} else {
			setShouldCameraFollow(true);
			setIsVisible(true);
		}

		if(gameController.gameState.currentPlayerInteraction.type == "toilet"){
			setNudityFilter(true);
		} else {
			setNudityFilter(false);
		}
	}

	
	useEffect(()=>{
		gameController.addEventHandler(["currentPlayerInteraction:updated"], 'player', onPlayerCourse);
		gameController.player.setPlayerReference(ref.current);
		return () => {
			gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'player', onPlayerCourse);
		};
	}, []);

	useEffect(()=>{
		
		console.log("currentAnimation", currentAnimation);
		if(currentAnimation == "Walk") {
			gameController.audio.playAudio('walk',1, 0.95, true);
		} else if(currentAnimation == "Run") {
			gameController.audio.playAudio('walk',1, 1.7, true);
		} else {
			gameController.audio.pauseAudio('walk');
		}
		// Multiplayer only
		if(!player) return;
		player.setState("animation", currentAnimation);
	}, [currentAnimation]);
	

    return (
		<group visible={isVisible}>
			<KeyboardControls map={keyboardMap}>
				<Ecctrl
					ref={ref}
					position={initialPosition}
					disableFollowCam={!shouldCameraFollow}
					cam
					maxVelLimit={2}
					autoBalance={true}
					camCollision={false}
					animated
					mode="FixedCamera"
					camInitDir={{x:0.4, y: 0}}
				>
					<EcctrlAnimation
						characterURL={characterURL} // Must have property
						animationSet={animationsMap} // Must have property
					>
						{ nudityFilter ? <mesh>
							<boxGeometry args={[0.7,0.7,0.7]}/>
							<meshStandardMaterial  attach="material-0" side={2} map={nudityTextures[(nudityIndex+0)%3]} />
							<meshStandardMaterial  attach="material-1" side={2} map={nudityTextures[(nudityIndex+1)%3]}  />
							<meshBasicMaterial attach="material-2" color="black" transparent={true} opacity={0}  />
							<meshBasicMaterial attach="material-3" color="black" transparent={true} opacity={0}  />
							<meshStandardMaterial  attach="material-4"  side={2} map={nudityTextures[(nudityIndex+2)%3]}  />
							<meshStandardMaterial  attach="material-5" color="white" side={2} map={nudityTextures[(nudityIndex+3)%3]}  />
						</mesh> : null}
						<primitive object={scene}  position={[0,-0.92,0]} scale={scale} />
					</EcctrlAnimation>
				</Ecctrl>
			</KeyboardControls>
		</group>
    )
  
};