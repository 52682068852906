import React, { useEffect, useRef, useState } from "react";
import { useGameController } from "../hooks/useGameController.ts";
import { FileSystem}  from "../os/FileSystem.ts";

import CloseIcon from '../assets/img/icons/close.svg';
import FileIcon from '../os/assets/file.svg';
import FolderIcon from '../os/assets/folder.svg';
import '../os/assets/os.css';
import { useFileSystem } from "../hooks/useFileSystem.ts";
import '../assets/css/computer-gui.css';
import CodeChallenge from "../code-garage/CodeChallenge.tsx";
import { Computer } from "../code-garage/Computer.tsx";

export default function ComputerGUI(){
    
    const gameController = useGameController();
    const [currentInteraction, setCurrentInteraction] = useState(gameController.getGameState().currentPlayerInteraction);
    const filesystem = useFileSystem();
    const [currentDirectory, setCurrentDirectory] = useState(null);


    useEffect(() => {
        
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'computer-ui', handler);
        
        filesystem.subscribe("currentDirectory:updated", (event, newCurrentDirectory) => {
            setCurrentDirectory(newCurrentDirectory);
        });

        filesystem.loadSystemFromJson({
            name: "",
            creationDate: new Date(),
            children: [
                { filename: "file1.txt", content: "Hello World", creationDate: new Date(), },
                { filename: "file2.txt", content: "Hello World 2", creationDate: new Date(), },
                { name: "test", children: [
                    { filename: "file3.txt", content: "Hello World 3", creationDate: new Date(), },
                    { filename: "file4.txt", content: "Hello World 4", creationDate: new Date(), },
                ]},
            ]
        });
        
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'computer-ui', handler);
        };
    }, []);

    function renderFile(file){
        return (
            <button key={file.filename} className="file">
                <img src={FileIcon} />
                <span>{file.filename}</span>
            </button>
        );
    }

    function renderFolder(folder){
        return (
            <button key={folder.name} className="folder" onClick={()=>{filesystem.changeDirectory(folder.getPath())}}>
                <img src={FolderIcon} />
                <span>{folder.name}</span>
            </button>
            );
    }

    function renderFolderContent(folder){
        return (
            <>
                { folder.name ? renderFolder({name: "..", children: [], getPath: () => {return '..'}}) : null}
                {folder.children.map((child, index) => child.children ? renderFolder(child) : renderFile(child))}
            </>
        )
    }

    function close(){
        gameController.setCurrentPlayerInteraction(null, null);
    }


    return currentInteraction && currentInteraction.type == 'computer' ?
    (   <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent', padding: '40px'}}>
            <div onClick={()=>{gameController.setCurrentPlayerInteraction(null, null)}} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            </div>
        
            <div className="computer-container">
                <div className="computer-gui">
                    { /*currentDirectory ?
                        renderFolderContent(currentDirectory)
                    : null */}
                    { currentInteraction.data.url ? <iframe src={currentInteraction.data.url}></iframe> : null }
                    { currentInteraction.data.computer ? <Computer currentTab={currentInteraction.data.defaultTab} disabledTabs={currentInteraction.data.disabledTabs}/> : null }
                </div>
                <button className="btn danger lg" style={{position: 'absolute', bottom:10, left: 'calc(50% - 80px)', width: 160}} onClick={()=>close()}>
                    <img src={CloseIcon} style={{height: 20}} /> Close
                </button>
            </div>
        </div>
        </>
    ) : null;
        
}