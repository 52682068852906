import React, { useEffect, useRef, useState } from 'react';
import {  CuboidCollider } from '@react-three/rapier';
import { useGameController } from './hooks/useGameController.ts';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useFrame, useLoader } from '@react-three/fiber';
import { MathUtils } from 'three';

export default function InteractionZone({ uid, id, position, initialRotation, scale, data }) {
    const { nodes } = useLoader(GLTFLoader, "data/models/Zone/zone.gltf");
    

    const gameController = useGameController();
    const [size, setSize] = useState(1.0);
    const [collision, setCollision] = useState(false);
    const [currentComputerSession, setCurrentComputerSession] = useState(gameController.getGameState().currentDiscussion);
    const [sceneState, setSceneState] = useState(gameController.getGameState().sceneState);
    const isInvisible = data?.invisible ?? false;
    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentComputerSession(newGameState.currentComputerSession);
            setSceneState(newGameState.sceneState);
        };
        gameController.addEventHandler(["game:updated"], uid, handler);
        return () => {
            gameController.removeEventHandler(["game:updated"], uid, handler);
        };
    }, []);

    const MAX_OPACITY = 0.6;
    const MIN_OPACITY = 0.3;

    const [rotation, setRotation] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const [opacityLoop, setOpacityLoop] = useState(1);

    useFrame(() => {
        setRotation(rotation + 0.01);
        setOpacityLoop(opacity > MAX_OPACITY ? -1 : (opacity < MIN_OPACITY ? 1 : opacityLoop));
        setOpacity((opacity + (0.005 * opacityLoop)));
        setSize(MathUtils.lerp(size, collision ? 1.3 : 1.0, 0.1));
    });

    function openComputer(){
        gameController.setCurrentPlayerInteraction("computer", data);
        gameController.audio.playAudio('computer',0.3);
        gameController.setSceneState("computer:interacted");
    }

    function onInteraction() {
       if(id === 'computer') {
            openComputer();
        } else if( id === 'level'){
            gameController.setCurrentPlayerInteraction("map", {levels: data.levels});
        } else if( id === 'toilet'){
            gameController.player.pee();
        } else if( id === 'bed'){
            gameController.player.sleep();
        } else if(id === 'game'){
            gameController.setCurrentPlayerInteraction("game", data);
        } else if(id === 'vending-machine'){
            gameController.setCurrentPlayerInteraction("shop", {items: data.items});
            /*const success = gameController.player.buy(data.amount);
            if(!success){
                return;
            }*/
        }
        if(data?.sceneState){
            gameController.setSceneState(data.sceneState);
        }
    }

    function onIntersectionEnter(){

        gameController.setPlayerHint(`(E) ${data.hint ?? 'Interact'}`);
        setCollision(true);
        gameController.addEventHandler(["player:interacted"], uid, onInteraction);
        
    }

    function onIntersectionExit(){
            gameController.removeEventHandler(["player:interacted"], uid, onInteraction);
            setCollision(false);
            gameController.clearPlayerHint();
    }
    
    useEffect(() => {
        return () => {
            try {
                this.gameController.removeEventHandler(["player:interacted"], uid, onInteraction);
            } catch (error) {}
        }
    }, []);
    
    return (
    <CuboidCollider 
        args={scale} 
        position={position} 
        sensor 
        onIntersectionEnter={() => onIntersectionEnter()} 
        onIntersectionExit={() => onIntersectionExit() }
    >
        { !isInvisible ? <mesh geometry={nodes.Cylinder001.geometry} position={[0,(size > 1 ? -size/4.7 : 0),0]} rotation={[0, rotation,0]} scale={[scale[0]*size, scale[1]/(size > 1 ? size*2 : size), scale[2]*size]}>
            <meshStandardMaterial color="#3498db" transparent opacity={opacity} />
        </mesh> : null }
    </CuboidCollider>
        
    );
};

    