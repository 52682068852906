export const missions = [
    {
        title: "Mission: You have 1 new email",
        description: "Our customer changed his email address and now...",
        difficulty: 1,
        rules: `
## Mission
Our client has changed their e-mail address, so he cannot access his website anymore.

## Goal
Change the value of the variable \`email\` to equal \`john@superskate.com\`
`,
        sampleCode: `let email = "john@my-super-skate.net";`,
        testSuite: [
            {func: `return email;`, expectedOutput: "john@superskate.com"}
        ]
    }
];