class File {

    parent: Folder;
    filename: string;
    extension: string;
    path: string;
    content: string;
    creationDate: Date;

    constructor(parent: Folder, fullname: string, content: string, creationDate?: Date) {
        const index = fullname.lastIndexOf('.');
        this.filename = fullname.substring(0, index);
        this.extension = fullname.substring(index, fullname.length);
        this.parent = parent;
        this.content = content;
        this.creationDate = new Date() || creationDate;
    }

    setContent(content: string) {
        this.content = content;
    }

    getSize() {
        return this.content.length * 8;
    }

    getFullname() {
        return this.filename + this.extension;
    }

    getPath() {
        return this.parent.getPath() + '/' ;
    }

    getFullPath() {
        return this.parent.getPath() + '/' + this.getFullname();
    }

};

class Folder {
    parent: Folder | null;
    name: string;
    children: Array<File|Folder>;
    creationDate: Date;

    constructor(parent: Folder | null, name: string, creationDate?: Date) {
        this.parent = parent;
        this.name = name;
        this.children = [];
        this.creationDate = creationDate || new Date();
    }

    getSize() {
        let size = 0;
        for (const child of this.children) {
            size += child.getSize();
        }
        return size;
    }

    createFile(name: string, content: string, creationDate?: Date) {
        const file = new File(this, name, content, creationDate);
        this.children.push(file);
        return file;
    }

    createFolder(name: string, creationDate?: Date) {
        const folder = new Folder(this, name, creationDate);
        this.children.push(folder);
        return folder;
    }

    getFile(name: string) {
        for (const child of this.children) {
            if (child instanceof File && child.getFullname() === name) {
                return child;
            }
        }
        return null;
    }

    getFolder(name: string) {
        for (const child of this.children) {
            if (child instanceof Folder && child.name === name) {
                return child;
            }
        }
        return null;
    }

    delete() {
        if(this.parent){
            this.parent.children = this.parent.children.filter((child) => child !== this);
        } else {
            throw new Error("Cannot delete root folder");
        }
    }

    getChildren() {
        return this.children;
    }

    getPath() {
        return this.parent ? this.parent.getPath() + '/' + this.name : this.name;
    }


};
export class FileSystem {
    
        currentFolder: Folder;
        root: Folder;
        eventListener: any;
    
        constructor() {
            this.root = new Folder(null, 'root');
            this.currentFolder = this.root;
        }

        subscribe(event: string, listener: any) {
            this.eventListener = listener;
        }
    
        createFile(path: string, content: string, creationDate?: Date) {
            const pathParts = path.split('/');
            let currentFolder = this.currentFolder;
            for (let i = 0; i < pathParts.length - 1; i++) {
                const folder = currentFolder.getFolder(pathParts[i]);
                if (folder) {
                    currentFolder = folder;
                } else {
                    currentFolder = currentFolder.createFolder(pathParts[i]);
                }
            }
            const file = currentFolder.createFile(pathParts[pathParts.length - 1], content, creationDate);
            if(currentFolder == this.currentFolder){
                this.eventListener("currentDirectory:updated", currentFolder);
            }
            return file;
        }
    
        getFile(path: string) {
            const pathParts = path.split('/').filter((part) => part);
            let currentFolder = this.currentFolder;
            for (let i = 0; i < pathParts.length - 1; i++) {
                const folder = currentFolder.getFolder(pathParts[i]);
                if (folder) {
                    currentFolder = folder;
                } else {
                    return null;
                }
            }
            const file = currentFolder.getFile(pathParts[pathParts.length - 1]);
            if(!file){
                throw new Error("File not found");
            }
            return file;
        }
    
        getFolder(path: string, currentFolder = this.currentFolder) {
            const pathParts = path.split('/').filter((part) => part);
            const folderToFind = pathParts[0];
            
            const folder = folderToFind === ".." ? currentFolder.parent : currentFolder.children.find((child) => child instanceof Folder && child.name === folderToFind);
            if(!folder){
                throw new Error("Folder not found");
            } else if(pathParts.length === 1){
                return folder;
            } else {
                return this.getFolder(pathParts.slice(1).join('/'), folder as Folder);
            }
        }
    
        delete(path: string) {
            const pathParts = path.split('/');
            let currentFolder = this.currentFolder;
            for (let i = 0; i < pathParts.length - 1; i++) {
                const folder = currentFolder.getFolder(pathParts[i]);
                if (folder) {
                    currentFolder = folder;
                } else {
                    return;
                }
            }
            const file = currentFolder.getFile(pathParts[pathParts.length - 1]);
            if (file) {
                currentFolder.children = currentFolder.children.filter((child) => child !== file);
                if(currentFolder == this.currentFolder){
                    this.eventListener("currentDirectory:updated", currentFolder);
                }
            }
        }

        changeDirectory(path: string) {
            const folder = this.getFolder(path);
            if (folder) {
                this.currentFolder = folder;
                this.eventListener("currentDirectory:updated", this.currentFolder);
            } else {
                throw new Error("Directory not found");
            }
        }
    
        getRoot() {
            return this.root;
        }

        loadSystemFromJson(json: any) {
            this.root = this.loadFolderFromJson(null, json);
            this.currentFolder = this.root;
            this.eventListener("currentDirectory:updated", this.currentFolder);
        }

        loadFolderFromJson(parent: Folder | null, json: any) {
            const folder = new Folder(parent, json.name, new Date(json.creationDate));
            if (!json.children) {
                return folder;
            }
            for (const child of json.children) {
                if (!child.children) {
                    folder.createFile(child.filename, child.content, new Date(child.creationDate));
                } else {
                    folder.children.push(this.loadFolderFromJson(folder, child));
                }
            }
            return folder;
        }

        getSystemTreeAsString(){
            return JSON.stringify(this.root, [ "filename", "name","children", "creationDate","path", "content", "extension"], 2);
        }
}