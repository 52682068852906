import React, { useEffect, useRef, useState } from 'react';
import { Box, Line } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useGameController } from './hooks/useGameController.ts';
import { ArrowHelper, MathUtils, Raycaster, Vector3 } from 'three';

export default function Wall({walls}) {
    const gameController = useGameController();
    const wallRef = useRef();
    const [playerPosition, setPlayerPosition] = useState([0,0,0]);
    const [leftCameraPosition, setLeftCameraPosition] = useState([0,0,0]);
    const [rightCameraPosition, setRightCameraPosition] = useState([0,0,0]);
    
    const raycasterLeft = new Raycaster();
    const raycasterRight = new Raycaster();

    useEffect(()=>{
        console.log(walls);
    },[]);
    useFrame(({camera})=>{
        if(camera && gameController.player?.reference){
            const playerPosition = gameController.player.reference.nextTranslation();
            const worldPosition = new Vector3();
            const cameraPosition = camera.getWorldPosition(worldPosition);
            //Deebug only
            setPlayerPosition([playerPosition.x, playerPosition.y, playerPosition.z]);
            //setCameraPosition([cameraPosition.x, cameraPosition.y, cameraPosition.z]);


            const playerVector = new Vector3(playerPosition.x, playerPosition.y, playerPosition.z);
            const cameraVector = new Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z);
            const angle = ((Math.PI / 180) * 15);
            const leftCameraVector = cameraVector.clone().applyAxisAngle(new Vector3(0, 1, 0), angle); // 5 degrees to the left
            const rightCameraVector = cameraVector.clone().applyAxisAngle(new Vector3(0, 1, 0), -angle); // 5 degrees to the right
            
            //setLeftCameraPosition([leftCameraVector.x, leftCameraVector.y, leftCameraVector.z]);
            //setRightCameraPosition([rightCameraVector.x, rightCameraVector.y, rightCameraVector.z]);

            const leftDirectionVector = leftCameraVector.clone().sub(playerVector).normalize()
            const rightDirectionVector = rightCameraVector.clone().sub(playerVector).normalize()
            
            const far = new Vector3(0,0,0).subVectors(playerVector, leftCameraVector).length();


            raycasterLeft.set(playerVector, leftDirectionVector);
            raycasterLeft.far = far;
            raycasterRight.set(playerVector, rightDirectionVector);
            raycasterRight.far = far;

            const leftIntersects = raycasterLeft.intersectObjects(walls);
            const rightIntersects = raycasterRight.intersectObjects(walls);

            const leftCollisions = leftIntersects/*.filter((intersect) => {
                const wall = intersect.object;
                const wallPosition = wall.position.clone();
                const cameraToPlayer = playerVector.clone().sub(leftCameraVector).normalize();
                const playerToWall = wallPosition.clone().sub(playerVector).normalize();
                const dotProduct = cameraToPlayer.dot(playerToWall);
                return dotProduct < 0;
            });*/

            const rightCollisions = rightIntersects/*.filter((intersect) => {
                const wall = intersect.object;
                const wallPosition = wall.position.clone();
                const cameraToPlayer = playerVector.clone().sub(rightCameraVector).normalize();
                const playerToWall = wallPosition.clone().sub(playerVector).normalize();
                const dotProduct = cameraToPlayer.dot(playerToWall);
                return dotProduct < 0;
            });*/

            const collisions = [...leftCollisions, ...rightCollisions];
            //console.log(collisions);
            walls.forEach((wall) => {
                if(wall.material){
                    const collisionFound = collisions.find((collision) => collision.object == wall);
                    if(collisionFound){
                        wall.material = wall.material.clone();
                        wall.material.transparent = true;
                        wall.material.opacity = MathUtils.lerp(wall.material.opacity, 0.0, 0.2);
                    } else {
                        wall.material = wall.material.clone();
                        wall.material.transparent = true;
                        wall.material.opacity = MathUtils.lerp(wall.material.opacity, 1, 0.3);
                    }
                }
            });
        }
    });
    return (
        <>
            { /*<Line points={[playerPosition, leftCameraPosition]} color="white" /> */}
            { /* <Line points={[playerPosition, rightCameraPosition]} color="white" /> */}
            { /*<Box ref={wallRef} args={props.scale} position={props.position} rotation={props.rotation}/> */ }
        </>
    );
    
};