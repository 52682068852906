import { motion } from 'framer-motion';
import React from 'react';

export const ProgressBar = ({ progress, variant }) => {
    return (
      <div className={`progress-bar ${variant.join(' ')}`}>
        <motion.div
          className="progress"
          initial={{ width: 0 }}
          animate={{ width: `${Math.max(progress, 5)}%` }}
          transition={{ duration: 0.6 }}
        />
        {progress}
      </div>
    );
  }