import { useEffect, useState } from "react"
import { useGameController } from "../hooks/useGameController.ts";
import CloseIcon from '../assets/img/icons/close.svg';

export default function SpaceInvaderGUI(){

    const [currentInteraction, setCurrentInteraction] = useState(null);
    const gameController = useGameController();

    useEffect(() => {
        const handler = (event, newGameState) => {
            setCurrentInteraction(newGameState.currentPlayerInteraction);
        };
        gameController.addEventHandler(["currentPlayerInteraction:updated"], 'space-invaders-gui', handler);
        return () => {
            gameController.removeEventHandler(["currentPlayerInteraction:updated"], 'space-invaders-gui', handler);
        }
    }, []);

    function close(){
        gameController.setCurrentPlayerInteraction(null, null);
    }

    return currentInteraction && currentInteraction.type == 'game' ? (
        <>
        <div  style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}>
            <div onClick={()=>close()} style={{position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1, top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.3)'}}>
            </div>
        
            <div style={{position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', zIndex: 2,backgroundColor: 'white', borderRadius: '5px', padding: '20px', height: 'calc(100% - 80px)', aspectRatio: currentInteraction.data.screenRatio}}>
                <iframe src={currentInteraction.data.url} style={{position: 'absolute', top: 20, left: 20, width: 'calc(100% - 40px)', height:'calc(100% - 40px)',border:'none'}} frameborder="0" scrolling="no"></iframe>
                <button className="btn danger lg" style={{position: 'absolute', top:-20, right: -20, width: 60}} onClick={()=>close()}>
                    <img src={CloseIcon} style={{height: 20}} />
                </button>
            </div>
        </div>
        </>
    ) : null;
}