import React, { memo, useEffect, useState } from 'react';
import '../assets/css/quiz.css';
import { AnimatePresence, motion } from "framer-motion";
import { ProgressBar } from '../components/ProgressBar.tsx';


const QuestionOption = memo(({ answer, currentAnswer, index, setCurrentAnswer, questionTitle }) => (
    <motion.li 
      className={"question-option " + (answer === currentAnswer ? 'active' : '')}
      key={index}
      initial={{ opacity: 0, scale: 0.5, translateY: -50 }}
      animate={{ opacity: 1, scale: 1, translateY: 0 }}
      exit={{ opacity: 0, translateY: 50, scale: 1.2 }}
      transition={{ duration: 0.6, delay: index * 0.2, type: "spring" }}
    >
      <label htmlFor={answer}>
        <span>{answer}</span>
        <input 
          type="radio" 
          id={answer} 
          name={questionTitle} 
          value={answer} 
          checked={answer === currentAnswer}
          onChange={(e) => setCurrentAnswer(e.currentTarget.value)} 
        />
      </label>
    </motion.li>
  ));
  
  const Question = ({ question, onAnswer }) => {
    const [currentAnswer, setCurrentAnswer] = useState('');

    const onAnswerClicked = () => {
        onAnswer(currentAnswer);
        setCurrentAnswer('');
    }
  
    return (
        <div className="question">
            <h2>{question.title}</h2>
            <ul className="question-options">
                {question.options.map((answer, index) => (
                <QuestionOption 
                    key={index} 
                    answer={answer} 
                    currentAnswer={currentAnswer} 
                    index={index} 
                    setCurrentAnswer={setCurrentAnswer} 
                    questionTitle={question.title} 
                />
                ))}
            </ul>
            <button className='btn primary lg' disabled={!currentAnswer} onClick={()=>onAnswerClicked()}>Valider</button>
      </div>
    );
  };

export default function Quiz({ quiz, onSuccess }){
    const [currentQuiz, setCurrentQuiz] = useState(quiz);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [state, setState] = useState("playing");

    const onAnswer = (answer) => {
        const result = currentQuiz.questions[currentQuestion].answer === answer;
        setAnswers([...answers, result]);
        if(currentQuestion + 1 === currentQuiz.questions.length){
            setState(answers.reduce((acc, a) => acc + a, 0) === currentQuiz.questions.length ? 'win' : 'lose');
            onSuccess();
            return;
        }
        setCurrentQuestion(currentQuestion + 1);
    }

    return (
            <div className={'quiz'}>
                <h1>{currentQuiz.title}</h1>
                <ProgressBar variant={['xl', 'primary']} progress={((answers.length) / currentQuiz.questions.length)*100} />
                { 
                    state === 'playing' ? (
                        <div>
                            <Question question={currentQuiz.questions[currentQuestion]} onAnswer={onAnswer} />
                        </div>
                    ) :
                    <div>
                        <h2>Score: {answers.filter(a => a).length}/{answers.length}</h2> 
                    </div>
                }
            </div>
    )
}