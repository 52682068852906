import React, { useEffect, useRef, useState } from 'react';
import { useFrame, useLoader } from '@react-three/fiber'
/* @ts-ignore */
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { CuboidCollider, RigidBody } from '@react-three/rapier';
import { useGameController } from './hooks/useGameController.ts';
import { MathUtils, LoopRepeat } from 'three'
import { useAnimations, useGLTF } from '@react-three/drei';

export default function NPC({ uid, id, position, rotation, scale }) {

    const models = {
      "developer": "data/models/FemaleDeveloper/female_developer.gltf",
      "boss": "data/models/Boss/boss.gltf",
      "teacher": "data/models/teacher.gltf",
      "salesman": "data/models/salesman.gltf",
      "clerk": "data/models/clerk.gltf",
    }

    const gameController = useGameController();
    const gltf = useGLTF(models[id]);
    const questionMark = useLoader(GLTFLoader, 'data/models/QuestionMark/question_mark.gltf');

    const { ref, mixer, names, actions, clips } = useAnimations(gltf.animations)
    const collision = useRef(false);

    const questionMarkRef = useRef();
    const [questionMarkZoom,setQuestionMarkZoom] = useState(1);
    
    function onInteraction(event) {
      gameController.startDiscussion(id);
    }

    function onIntersectionEnter(){
      
      gameController.addEventHandler(["player:interacted"], uid, onInteraction);
      collision.current = true;
      gameController.setPlayerHint("(E) Talk");
  }

  function onIntersectionExit(){
      gameController.removeEventHandler(["player:interacted"], uid, onInteraction);
      collision.current = false;
      gameController.clearPlayerHint();
      
  }

  function onCourseStart(event, data){
    if(id === "teacher" && gameController.gameState.currentPlayerInteraction?.type === "course"){
      actions?.Idle.play();
      actions?.Idle?.setLoop(LoopRepeat, Infinity);
    }
  }

    useEffect(() => {
      gameController.addEventHandler(["currentPlayerInteraction:updated"], uid, onCourseStart);
      return () => {
        gameController.removeEventHandler(["currentPlayerInteraction:updated"], uid, onCourseStart);
      }
    }, []);

    useFrame(() => {
      questionMarkRef.current.rotation.y += 0.02;
      
      setQuestionMarkZoom(MathUtils.lerp(questionMarkZoom,collision.current ? 1.2 : 1.0,0.1));
    });
    
    return (
      <>
        <RigidBody type={'fixed'} friction={0} position={position} rotation={rotation}>
          <CuboidCollider 
            args={[1,1,1]} 
            position={[0,1,0]}
            sensor 
            onIntersectionEnter={() => onIntersectionEnter() } 
            onIntersectionExit={() => onIntersectionExit() }
          >
            <primitive ref={questionMarkRef} object={questionMark.scene} position={[0,1.1*questionMarkZoom,0]} scale={[questionMarkZoom,questionMarkZoom,questionMarkZoom]}></primitive>
            <primitive ref={ref} object={gltf.scene} position={[0,-1,0]} scale={scale} />
            </CuboidCollider>
        </RigidBody>
      </>
    )
  
};