export function useStrapiUtils(){

    function parseError(error){
        if(error.details){
            if(error.details.errors){
                return translateError(error.details.errors[0].message);
            }
        }
        return translateError(error.message);
    }

    function translateError(error){
        const translations = {
            "identifier": "email"
        };
        const translated = Object.entries(translations).reduce((acc, [key, value])=>{
            return acc.replace(key, value);
        }, error);
        if(translated != error){
            return translateError(translated);
        }
        return error;
    }

    return {
        parseError,
        translateError
    }
}