import { GameController } from "./GameController";

export default class PlayerController {
    gameController: GameController;
    experience: number;
    level: number;
    money: number;
    username: string;
    reference: any;
    inventory: {[key: string]: any};

    constructor(gameController: GameController){
        this.gameController = gameController;
        this.experience = 0;
        this.level = 1;
        this.money = 10;
        this.username = this.generateUsername();
        this.inventory = {};
    }

    setPlayerReference(reference: any){
        this.reference = reference;
    }

    generateUsername(){
        return "#"+(Math.random()).toString().substring(3,6);
    }

    addExperience(amount: number){
        this.experience += amount;
        if(this.experience >= this.getNextLevelThreshold()){
            this.level++;
            this.experience = 0;
        }
        this.gameController.sendEvent("player:updated", {experience: this.experience, level: this.level, money: this.money});
    }

    addMoney(amount: number){
        this.gameController.audio.playAudio('money',1);
        this.money += amount;
        this.gameController.sendEvent("player:updated", {experience: this.experience, level: this.level, money: this.money});
        this.gameController.sendEvent("player:money:updated", {amount: amount, total: this.money});
        this.gameController.setSceneState("money:taken");
    }

    useMoney(amount: number){
        this.money -= amount;
        this.gameController.sendEvent("player:updated", {experience: this.experience, level: this.level, money: this.money});
        this.gameController.sendEvent("player:money:updated", {amount: -amount, total: this.money});
    }

    getNextLevelThreshold(){
        return 10;
    }

    eat(){
        this.gameController.audio.playAudio('eat',1);
    }
    drink(){
        this.gameController.audio.playAudio('drink',1);
    }
    buy(key: string, amount: number, sceneState?: string){
        if(this.money < amount){
            this.gameController.audio.playAudio('error',0.3);
            return false;
        }
        this.gameController.audio.playAudio('buy',1);
        this.useMoney(amount);
        this.gameController.addElementToScene(key, [0,0.5,0]);
        if(sceneState){
            this.gameController.setSceneState(sceneState);
        }
        return true;
    }

    setUsername(username: string){
        this.username = username;
        this.gameController.sendEvent("username:updated", {username: username});
    }

    sleep(){
        this.gameController.setCurrentPlayerInteraction("sleep", {});
        this.gameController.audio.playAudio('sleep',0.5,1.5);
        setTimeout(()=>{
            this.gameController.setCurrentPlayerInteraction(null, {});
            this.gameController.audio.pauseAudio('sleep');
            this.gameController.audio.playAudio('alarm',0.3,2);
        }, 3500);
    }

    pee(){
        this.gameController.setCurrentPlayerInteraction("toilet", {});
        this.gameController.audio.playAudio('pee',0.5,1.5);
        setTimeout(()=>{
            this.gameController.setCurrentPlayerInteraction(null, {});
        }, 2000);
    }
}